@mixin heading($f-size) {
  font-size: $f-size;
  font-family: $secondaryFontFamily;
}

h1,
.h1 {
  @include heading(3.2rem);
  font-weight: $semiBold;
  line-height: 1.33;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

h2,
.h2 {
  @include heading(2.8rem);
  font-weight: $semiBold;
  line-height: 1.33;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

h3,
.h3 {
  @include heading(2.4rem);
  font-weight: $semiBold;
  line-height: 1.33;
  margin-top: 1em;
  margin-bottom: 1em;
}

h4,
.h4 {
  @include heading(1.6rem);
  font-weight: $semiBold;
  line-height: 1.33;
  margin-top: 1em;
  margin-bottom: 1em;
}

h5,
.h5 {
  @include heading(1.4rem);
  font-weight: $semiBold;
  line-height: 1.33;
  margin-top: 1em;
  margin-bottom: 1em;
}

@include media-breakpoint-up(lg) {
  h1,
  .h1 {
    @include heading(4.8rem);
  }

  h2,
  .h2 {
    @include heading(4rem);
  }

  h3,
  .h3 {
    @include heading(3.2rem);
  }

  h4,
  .h4 {
    @include heading(2.4rem);
  }

  h5,
  .h5 {
    @include heading(1.6rem);
  }
}
