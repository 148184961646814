.maintenance {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }

  .topbar {
    margin-top: 30px;
  }

  .container {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;

    h1 {
      font-size: 20px;
    }

    .body {
      font-size: 16px;
    }
  }
  .footer-container {
    margin-top: 5rem;
    text-align: center;
  }
}
