.modal-alert-stock {
    .subscribe-to-in-stock-alert {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        background-color: $pink;
    }
    .customer-email {
        background-color: $white;
        width: 300px;
    }
}