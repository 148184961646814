$frontCommercePrimary: #ab083e;
$black: #1a1a1a;
$realBlack: #000000;
$grey: #f9f9f9;
$darkGrey: #707070;
$white: #fff;
$blue: #408fdb;
$pink: #fff3ed;
$orange: #f45610;

$borderGrey: #e6e6e6;
$borderGreyLight: #f7f7f7;
$borderGreyDarkness: #dcdcdc;

$blackDisabled: #1a1a1a99;
$blueDisabled: #408fdb99;
$blueEnforced: #0e61b0;
$pinkDisabled: #fff3ed99;
$pinkEnforced: #fde0d2;

$brandPrimary: $blue;
$brandSecondary: $pink;
$red: #ff0000;

$boxShadow: 0 0 4px 0 rgba(188, 188, 188, 0.5);
$menuBoxShadow: 0 2px 4px 0 rgba(239, 239, 239, 0.5);

$shade01: $white;
$shade02: $grey;
$shade03: #dadada;
$shade04: #9b9b9b;
$shade05: #333333;
$shade06: $black;

$success: $black;
$info: $black;
$warning: $orange;
$error: $orange;

$divider: $shade03;
$blockBackground: $white;
$callToAction: $brandPrimary;

$fontDisabled: $shade05;
$fontSecondaryColor: $shade05;
$titleColor: $brandPrimary;

$backgroundColor: $grey;
$fontColor: $black;
$reverseBackgroundColor: $fontColor;
$reverseFontColor: $backgroundColor;

$buttonPrimaryColor: $brandPrimary;
$buttonSecondaryColor: $white;

$callToActionColor: $buttonSecondaryColor;
$callToActionBackground: $buttonPrimaryColor;

$hoverableColor: $darkGrey;

$iconColor: $brandPrimary;
$notificationColor: $white;
$notificationBackground: $brandPrimary;

$modalColor: $black;
$modalBackground: $backgroundColor;
$modalBorderColor: $shade04;

$productBackground: $backgroundColor;
$productColor: $black;

$productPriceColor: $fontSecondaryColor;
$promotionBorderColor: $brandSecondary;

$optionBackground: $shade03;
$optionColor: $black;
$optionItemBackground: $white;
$optionItemBorderColor: $shade04;
$optionItemColor: $fontDisabled;
$optionItemActiveBorderColor: $shade04;

$inputBorderColor: $shade04;

/* export variables used in `Colors.story.js` */
:export {
  brandprimary: $brandPrimary;
  brandsecondary: $brandSecondary;

  shade01: $shade01;
  shade02: $shade02;
  shade03: $shade03;
  shade04: $shade04;
  shade05: $shade05;
  shade06: $shade06;

  uisuccess: $success;
  uiinfo: $info;
  uiwarning: $warning;
  uierror: $error;

  text-black: $black;
  text-white: $white;
}
