.multistep {
  font-size: $regularSize;
  background-color: $grey;

  p {
    margin-bottom: $boxSizeMargin * 2;
    font-size: $regularSize;
  }

  .information-box {
    background-color: $brandSecondary;
    display: flex;
    padding: $boxSizeMargin * 2;
    margin-bottom: $boxSizeMargin * 2;
    gap: $boxSizeMargin * 2;
  }
  &__wrapper {
    @include mobile-maxwidth();
  }

  &__back {
    padding: $boxSizeMargin 0;
  }

  .button--link,
  fieldset,
  .h1 {
    font-size: $regularSize;
  }

  .item-with-addon {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    flex: 1;
    flex-wrap: wrap;
    .container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .img {
        width: 70%;
        text-align: right;
        place-self: center;
      }

      .card-img {
        place-self: center;

        img {
          width: 130px;
        }
      }
    }
    &__name {
      display: flex;
      align-items: center;
    }
    .label {
      width: 30%;
      place-self: center;
    }
    .responsive-image {
      margin-right: 20px;
      width: 100px;
      height: auto;
      img {
        object-fit: cover;
      }
    }
  }

  &__current-step {
    .multistep-box {
      background-color: $white;
      padding: $boxSizeMargin * 1.6;

      + .multistep-box {
        margin-top: $boxSizeMargin;
      }
    }
    .shipping-step {
      fieldset > .item-with-addon {
        background-color: $white;
        padding: $boxSizeMargin $boxSizeMargin * 2.8;
      }
      &__additional-data {
        background-color: $white;
        padding: $boxSizeMargin * 1.6;
      }
      .special-price {
        display: inline-block;
        text-decoration: line-through;
        margin-right: 10px;
      }
    }
    .h1 {
      font-weight: $bold;
      text-align: left;
      margin-bottom: $boxSizeMargin * 2;
    }

    .action-fixed .form-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $grey;
      margin: 0;
      padding: $boxSizeMargin 0;
      z-index: 1;

      .button {
        margin: 0;
      }
    }

    fieldset,
    .radio {
      max-width: none;
    }
    .multistep-box {
      padding: 0;
      background-color: transparent;
      &__content {
        background-color: $white;
        padding: $boxSizeMargin;
      }
      + .multistep-box {
        margin-top: $boxSizeMargin;
      }
    }

    .shipping-step .radio,
    .payment-step .radio,
    .payment-step .radio + div:not(.radio) {
      background-color: $white;
      border: none;
      padding: $boxSizeMargin * 2;
      margin-top: $boxSizeMargin * 1.2;
      &:first-child {
        margin: 0;
      }
      label {
        cursor: pointer;
      }
    }
    .payment-step .radio,
    .payment-step .radio + div:not(.radio) {
      padding: $boxSizeMargin;
      margin-top: $boxSizeMargin * 0.5;
    }
    .payment-step .radio + div:not(.radio) {
      margin-top: 0;
      padding: $boxSizeMargin;
    }
    .payment-step .radio + div:not(.radio) {
      margin-top: 0;
      padding: $boxSizeMargin;
    }
  }

  &__recap {
    position: fixed;
    bottom: 60px;
    max-height: 40vh;
    overflow: auto;
    z-index: 2;
    background: white;
    left: 0;
    right: 0;
    .cart-content__payment-icon {
      display: none;
    }
    &__title {
      display: none;
    }
    &__item {
      margin-top: $boxSizeMargin * 2;
    }
  }

  .form-item__label label {
    font-size: $regularSize;

    strong {
      font-weight: $regular;
    }
  }
}

.gsc {
  margin-top: $boxSizeMargin * 1.6;

  .button--link {
    color: $brandPrimary;
    text-align: left;
    height: auto;
  }
}

.address-step,
.shipping-step,
.payment-step {
  .or-divider {
    text-align: center;
    font-weight: $bold;
    padding: $boxSizeMargin * 2 0;
    font-size: $regularSize;
  }

  .radio {
    border-bottom: 1px solid $borderGrey;
    padding: $boxSizeMargin * 2.6 0;
    .item-with-addon__name {
      width: 100%;
    }
    .item-with-addon .label {
      width: auto;
    }
  }
  .choose-address {
    margin-bottom: $boxSizeMargin * 2;
  }
  .address-button {
    text-align: center;
  }
  .payzen-form {
    padding: 20px;
    background-color: white;
    .form-item {
      margin-bottom: $boxSizeMargin * 3;
    }
  }
}

.address-step {
  .grid {
    display: block;

    &__col {
      margin: 0;

      + .grid__col {
        margin-top: $boxSizeMargin;
      }
    }
  }

  form .checkbox {
    margin-top: $boxSizeMargin;
  }

  .radio .dropdown {
    width: 100%;
  }
  .react-select {
    .select__control {
      cursor: pointer;
    }
    .select__value-container {
      height: auto;
    }
    .select__single-value {
      height: auto;
    }
    .select__menu {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .multistep {
    .item-with-addon {
      .img {
        text-align: left;
      }
    }

    &__wrapper {
      @include container-maxwidth();
      max-width: 1160px;
      margin-top: $boxSizeMargin * 3.5;
    }

    &__content {
      display: flex;
      align-items: flex-start;
    }

    &__current-step {
      flex: 1;
      margin-right: $boxSizeMargin * 2.4;

      .action-fixed .form-actions {
        position: inherit;
        background-color: inherit;
      }
      .multistep-box {
        &__content {
          padding: $boxSizeMargin * 4.8 $boxSizeMargin * 12;
        }
        + .multistep-box {
          margin-top: $boxSizeMargin * 2;
        }
      }
      .shipping-step .radio {
        padding: $boxSizeMargin $boxSizeMargin * 2.8;
      }
      .payment-step .radio + div:not(.radio) {
        padding: $boxSizeMargin $boxSizeMargin * 1;
      }
      .payment-step .radio + div:not(.radio) {
        margin-top: 0;
        padding: $boxSizeMargin $boxSizeMargin * 1;
      }
    }

    &__recap {
      position: initial;
      bottom: 0;
      max-height: initial;
      overflow: auto;
      z-index: 1;
      margin-left: auto;
      width: 340px;
      .cart-content__payment-icon  {
        display: block;
      }
      .form-actions {
        margin-top: $boxSizeMargin;
      }

      &__item {
        margin-top: 0;

        + .multistep__recap__item {
          margin-top: 2rem;
        }
      }
    }
  }
}
