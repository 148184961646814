@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.spinner {
  $spinnerSize: $boxSizeMargin * 8;
  $spinDuration: 1.2s;
  $headerHeight: 95px;

  display: block;
  position: relative;
  margin: auto;
  height: $spinnerSize;
  max-width: 100%;

  &__loading {
    .icon {
      position: relative;

      svg {
        height: $spinnerSize * 0.5;
        width: $spinnerSize * 0.5;
      }
    }

    .icon:before {
      content: "";
      box-sizing: border-box;
      width: $spinnerSize;
      height: $spinnerSize;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -$spinnerSize * 0.5;
      margin-left: -$spinnerSize * 0.5;
      border-radius: 50%;
      border: 0.7rem solid transparent;
      border-top-color: $brandPrimary;
      animation: spin $spinDuration infinite cubic-bezier(0.7, 0.15, 0.3, 0.85);
    }
  }

  &--fullpage {
    height: calc(100vh - #{$headerHeight});

    .spinner__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -$headerHeight * 0.5;
      transform: translate(-50%, -50%);

      .icon svg {
        height: $spinnerSize * 0.75;
        width: $spinnerSize * 0.75;
      }

      .icon:before {
        width: $spinnerSize * 1.5;
        height: $spinnerSize * 1.5;
        margin-top: -$spinnerSize * 0.75;
        margin-left: -$spinnerSize * 0.75;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--fullpage {
      $headerHeight: 140px;

      height: calc(100vh - #{$headerHeight});

      .spinner__loading {
        margin-top: -$headerHeight * 0.5;
        transform: translate(-50%, -50%);

        .icon svg {
          height: $spinnerSize;
          width: $spinnerSize;
        }

        .icon:before {
          width: $spinnerSize * 2;
          height: $spinnerSize * 2;
          margin-top: -$spinnerSize;
          margin-left: -$spinnerSize;
        }
      }
    }
  }
}
