.size-guide {
  margin-top: 0;
  margin-bottom: $boxSizeMargin;

  .wrapper-sizeguide {
    display: inline-flex;
  }

  .logo-sizeguide {
    width:3em;
    float: left;
    height: 3em;
    margin-right: 1em;
  }

  .size-message {
    text-align: left;
    display: inline-block;
  }

  .button {
    border: none;
    padding: 0;
    font-size: $extraSmallSize;
    font-family: $fontFamily;
    min-height: auto;
    span {
      text-decoration: underline;
      font-weight: $regular;
    }
    .default {
      margin-left: 1em;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      color: $black;
    }
  }

  .labelled-icon {
    font-weight: $regular;
  }
  &__tab {
    width: 100%;
    margin-top: $boxSizeMargin * 2;
    font-size: $smallSize;
    overflow: auto;
    table {
      border-collapse: collapse;
      border: 1px solid $borderGrey;
    }
    &__line {
      border: 1px solid $borderGrey;
      &:first-child {
        font-size: $smallSize;
        .size-guide__tab__column {
          font-weight: $bold;
        }
      }
    }
    &__column {
      border: 1px solid $borderGrey;
      text-align: center;
      box-sizing: border-box;
      padding: $boxSizeMargin;
      width: 20%;
      min-width: 100px;
      font-size: $extraSmallSize;
      font-weight: $regular;
      &:first-child {
        font-weight: $bold;
        font-size: $smallSize;
      }
    }
  }
  &__modal {
    &__title {
      display: block;
      font-weight: $bold;
      margin-bottom: $boxSizeMargin;
    }
    &__description {
      display: block;
    }
    .image {
      margin: $boxSizeMargin 0;
    }

    .wysiwyg {
      margin-top: $boxSizeMargin * 2;
      padding: $boxSizeMargin;
      box-sizing: border-box;
      overflow: auto;
      width: 100%;
      background-color: $pink;
    }
    &__btn-action {
      text-align: center;
    }
    &__content {
      padding: $boxSizeMargin;
    }
  }
}
