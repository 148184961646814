.product-review {
    padding: $boxSizeMargin * 2;
    max-height: 300px;
    overflow: auto;
    &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $boxSizeMargin *2;
        &__detail {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            & > div {
              align-items: center;
              width: 100%;
              justify-content: space-between;
              display: flex;
              & > div:nth-child(1) {
                display: flex;
                flex-direction: column;
              }
            }
            p {
                width: 100%;
                padding-left: $boxSizeMargin;
                color: $shade04;
                margin: 5px 0;
                font-weight: $bold;
                font-size: $smallSize;
            }
        }
        &__rating {
          width: 150px;
          text-align: right;
        }
    }
}
.rating-star {
    position: relative;
    
    display: inline-block;
    width: 0;
    height: 0;
    
    margin-left: .9em;
    margin-right: .9em;
    margin-bottom: 1.2em;
    
    border-right:  .3em solid transparent;
    border-bottom: .7em  solid $darkGrey;
    border-left:   .3em solid transparent;
  
    /* Controlls the size of the stars. */
    font-size: .8rem;
    
    &:before, &:after {
      content: '';
      
      display: block;
      width: 0;
      height: 0;
      
      position: absolute;
      top: .6em;
      left: -1em;
    
      border-right:  1em solid transparent;
      border-bottom: .7em  solid $darkGrey;
      border-left:   1em solid transparent;
    
      transform: rotate(-35deg);
    }
    
    &:after {  
      transform: rotate(35deg);
    }
    &.active {
        border-bottom: .7em  solid #ffc107;
        &:before, &:after {
            border-bottom: .7em  solid #ffc107;
          }
    }
  }