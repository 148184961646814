select {
  background-color: $white;
  border: 1px solid $borderGrey;
  padding: 0.6em 3em 0.6em 1em;
  color: $black;
  outline: none;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }

  &--classic {
    :last-child {
      position: absolute;
      right: 0.3em;
      top: 0.32em;
      pointer-events: none;
    }

    option {
      padding: 0.3rem 0;
    }
  }
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  .icon {
    position: absolute;
    right: 15px;
    top: 16px;
    font-size: 1.3em;
    pointer-events: none;
  }
}

.react-select {
  text-align: left;

  input:-webkit-autofill {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }

  .select {
    &__value-container {
      padding: 0 0 0 $boxSizeMargin * 1.6;
      height: 4rem;
      left: 0;

      &--has-value {
        > * {
          margin: 0;
          padding: 0;
        }
      }
    }

    &__control {
      border-color: $borderGrey;
      box-shadow: none;
      border-radius: 0px;

      &--is-focused {
        border: 1px solid $borderGrey;
      }

      &--is-open {
        border-color: $borderGrey;
      }

      &:hover {
        border: 1px solid $borderGrey;
      }
    }
    &__indicators {
      padding-right: 0.8rem;
      padding-left: 0.8rem;
    }

    &__indicator {
      cursor: pointer;
      color: $black;
      &-separator {
        display: none;
      }
    }

    &-clear {
      &:hover {
        color: $brandSecondary;
      }
    }

    &__control,
    &__option {
      color: $black;
    }

    &__placeholder {
      color: $darkGrey;
      width: auto;
      max-width: none;
    }

    &__placeholder,
    &__single-value {
      padding: 0;
      margin: 0;
      height: 4rem;
      display: flex;
      align-items: center;
    }

    &__menu {
      border: 1px solid $borderGrey;
      margin-top: $boxSizeMargin * 0.5;
      background: $white;
      z-index: 2;
      width: auto;
    }

    &__option {
      &--is-focused,
      &:hover,
      & {
        background: transparent;
        &:hover {
          background: transparent;
        }
      }

      &:hover {
        color: $brandPrimary;
        cursor: pointer;
      }

      &--is-disabled {
        color: $darkGrey;
        pointer-events: none;
        text-decoration: line-through;

        &:hover {
          color: $darkGrey;
          cursor: default;
        }
      }
    }
  }

  .select__menu {
    z-index: 3;
  }

  &.select--big {
    .select {
      &__menu {
        min-width: 100%;
      }
    }
  }

  &.select--light {
    .select {
      &__single-value {
        width: auto;
        max-width: none;
        padding-right: $boxSizeMargin;
      }
      &__value-container {
        padding: 0;
      }
      &__control {
        border: none;
      }
      &__menu {
        min-width: 5rem;
        width: 100%;
      }
    }
  }
}
