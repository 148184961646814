.wrapper--refreshing {
  cursor: wait;
  opacity: 0.2;
}

.checkout-footer {
  padding-bottom: 60px;
  .cookie-link {
    display: none;
  }
}

.checkout-header.topbar {
  padding-bottom: $boxSizeMargin * 0.5;

  .container {
    padding-bottom: 0;
  }

  .topbar {
    &__wrapper {
      display: block;
      &__logo {
        text-align: left;
        display: block;
        margin-left: 0;

        img {
          width: 80px;
          height: auto;
        }
      }
    }
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 2rem;
  .mdl-layout__content > .spinner {
    background-color: $white;
    @include container-maxwidth();
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wrapper > .container {
  margin: 0 auto;
}

.container--white {
  background: $white;
}

.container--grey {
  background: $grey;
}

.wrapper > .container--white,
.wrapper > .container--grey {
  margin: 0 auto;
  padding: 0;
}

.container--white,
.container--grey {
  box-sizing: border-box;

  .container__content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
.responsive-image--loading {
  position: relative;
  overflow: hidden;
  &::before {
    z-index: 1;
    @keyframes progress {
      0% {
        transform: translate3d(-100%, 0, 0);
      }
      100% {
        transform: translate3d(100%, 0, 0);
      }
    }
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      #e9e9e9,
      lighten($borderGreyLight, 7%),
      #e9e9e9
    );
    animation: progress 2s ease-in-out infinite;
  }
  .responsive-image__placeholder {
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .checkout-header.topbar {
    .topbar__wrapper__logo {
      height: 50px;
      margin-top: $boxSizeMargin * 1.6;
      padding: 10px;
      svg {
        height: 100%;
        width: auto;
      }
      img {
        width: 130px;
      }
    }
  }

  .checkout-footer {
    padding-bottom: 0;
  }
}

@import "./Header/Header";
@import "./Header/TopBar";
@import "./Header/MenuNavigation/MenuNavigation";
@import "./Header/MenuNavigation/MenuPopin";
@import "./Footer/Footer";
@import "./Footer/BottomBar";
@import "./Footer/SocialNewsletterBar";
