.form-item {
  display: flex;
  text-align: left;
  flex-direction: column;

  &__label > * {
    font-size: $smallSize;
    width: 100%;
    align-self: center;
  }
  
  &__input > * {
    width: 100%;
    align-self: center;
    margin-top: $boxSizeMargin * 0.5;
  }

  &--inline {
    flex-direction: row;
    align-items: center;

    .form-item__label > *,
    .form-item__input > * {
      width: auto;
    }

    .form-item__input > * {
      margin-top: 0;
    }
  }

  &--inverted {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  margin: $boxSizeMargin 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .form-item {
    &__label > * {
      font-size: $regularSize;
    }
  }
}
