.paginated-page__header {
  background-color: $pink;

  h1 {
    text-transform: capitalize;
    padding: $boxSizeMargin * 4 0;
    text-align: center;
    margin: 0;
  }
}
.paginated-page__headerfull {
  position: relative;
  max-height: 70vh;
  overflow: hidden;
  .spinner {
    background-color: $grey;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    width: 100%;
  }
  &__content {
    z-index: 2;
    text-transform: capitalize;
    position: absolute;
    max-width: 550px;
    width: 100%;
    padding: $boxSizeMargin * 5;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }
  .responsive-image--loading {
    height: 50vh;
    .responsive-image__placeholder {
      height: 100%;
      width: 100%;
    }
  }
  h1 {
    font-size: $giantSize;
    font-weight: $semiBold;
    margin: 0 auto 10px auto;
  }
}

@include media-breakpoint-up(lg) {
  .paginated-page__header {
    position: relative;
    background-color: $grey;
    .breadcrumb {
      background-color: transparent;
      display: inline-block;
      padding-left: 0;
      &__item {
        float: left;
      }
    }

    .h4 {
      position: relative;
      top: -3.6rem;
      margin: 0 auto;
    }
    .paginated-page__wrapper-background {
      @include container-maxwidth();
      background-color: $pink;
      height: 150px;
    }
  }
  .paginated-page__headerfull {
    max-height: initial;
    max-width: 1500px;
    margin: auto;
    width: 100%;
    .responsive-image__actual {
      height: 550px;
    }
    .image {
      width: 100%;
    }
    .responsive-image--loading {
      height: 550px;
    }
    .breadcrumb {
      background-color: transparent;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      margin: 0 auto;
      color: $white;
      z-index: 2;
      &__item {
        float: left;
      }
      span,
      a {
        color: $white;
      }
    }

    h1 {
      font-size: $extraGiantSize;
      font-weight: $semiBold;
    }
    span {
      font-weight: $regular;
      font-size: $regularSize;
    }
  }
}
