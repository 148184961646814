@import "./Fonts/fonts";

$fontFamily: "Muli", "Arial Helvetica", "Arial", sans-serif;
$secondaryFontFamily: "Montserrat", "Arial Helvetica", "Arial", sans-serif;

$light: 300;
$regular: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

$tinySize: 1.1rem;
$extraSmallSize: 1.2rem;
$smallSize: 1.4rem;
$veryLargeSize: 1.4rem;
$regularSize: 1.6rem;
$mediumSize: 1.6rem;
$largeSize: 2rem;
$extraLargeSize: 2.4rem;
$giantSize: 3.2rem;
$extraGiantSize: 4.8rem;

$mediumIconSize: 2.5rem;
$smallIconSize: 1.9rem;

$menuSize: 1.3rem;
$menuSmallSize: 1.1rem;

/* export variables used in `Typography.story.js` */
:export {
  family-roboto: $fontFamily;
  weight-regular: $regular;
  weight-semibold: $semiBold;
  weight-bold: $bold;
  size-tinysize: $tinySize;
  size-extrasmallsize: $extraSmallSize;
  size-smallsize: $smallSize;
  size-regularsize: $regularSize;
  size-mediumsize: $mediumSize;
  size-extralargesize: $extraLargeSize;
  size-giantsize: $giantSize;
  size-mediumiconsize: $mediumIconSize;
  size-smalliconsize: $smallIconSize;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}

body {
  background: $backgroundColor;
  color: $fontColor;
  font-family: $fontFamily;
  font-style: normal;
  font-size: 62.5%;
  font-weight: $regular;
  line-height: 1.5;
}

body {
  font-size: $smallSize;
}

@include media-breakpoint-up(lg) {
  body {
    font-size: $regularSize;
  }
}

@import "~theme/components/atoms/Typography/Body/Body";
@import "~theme/components/atoms/Typography/Heading/Heading";
@import "~theme/components/atoms/Typography/Link/Link";
@import "~theme/components/atoms/Typography/Price/Price";
