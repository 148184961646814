@import "./linearicons";

.lnr {
  font-size: 1.6rem;
  vertical-align: text-bottom;
  text-decoration: none;
}

.lnr--small {
  font-size: $smallIconSize;
  @include media-breakpoint-up(lg) {
    font-size: $mediumIconSize;
  }
}

.lnr--mini {
  color: $shade04;
  font-size: $smallSize;
}

.lnr--tiny {
  font-size: 1rem;
  vertical-align: middle;
}

.lnr--bold {
  font-weight: $bold;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: currentColor;
    height: 24px;
    width: 24px;
  }
}
.icon--huge svg {
  height: 30px;
  width: 30px;
}
.icon--small svg {
  height: 20px;
  width: 20px;
}
.icon--mini svg {
  height: 16px;
  width: 16px;
}

.icon--tiny svg {
  height: 10px;
  width: 10px;
}

.icon--chevron-left svg {
  transform: rotate(90deg);
}

.icon--chevron-right svg {
  transform: rotate(-90deg);
}

.icon--chevron-left svg {
  transform: rotate(90deg);
}

.icon--chevron-up svg {
  transform: rotate(180deg);
}

.button.button--icon {
  height: auto;
}
