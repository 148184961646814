.category-result {
  font-weight: normal;

  > :last-child {
    font-weight: $bold;
  }
}
.autocomplete__category {
  width: 100%;
  .autocomplete-item__name {
    display: flex;
    flex-direction: column;
    white-space: break-spaces;
    width: 40em;
    .autocomplete-item__addon {
      order: 2;
      display: flex;
    }
    > .link {
      order: 1;
     
    }
  }
}