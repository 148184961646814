.bottombar {
  background: $grey;
  color: $darkGrey;

  .wysiwyg {
    width: 100%;
  }

  &__column {
    width: 100%;
    &__wrapper {
      @include container-maxwidth();
      box-sizing: border-box;
      width: 100%;
      padding-top: $boxSizeMargin * 1.4;
      padding-bottom: $boxSizeMargin * 1.4;
      font-size: $menuSize;
    }
  }

  .link-list {
    &__list {
      display: none;

      text-indent: 0;
      margin-block-end: 0;
      padding-left: 0;
      list-style: none;

      &--notitle {
        display: block;
      }
    }
    &__title {
      font-size: $menuSize;
      font-weight: $bold;
    }
    &__item {
      margin-bottom: $boxSizeMargin * 0.8;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & + .link-list__item {
      margin-top: 0;
    }

    .button--link {
      font-size: inherit;
      font-weight: inherit;
      height: auto;
    }

    .link,
    .button--link {
      color: $darkGrey;
      &:hover {
        color: $brandPrimary;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;

    > * {
      display: flex;
    }
  }

  &__categories,
  &__brand-service {
    width: 100%;
  }

  .dropdown {
    @include container-maxwidth();
    box-sizing: border-box;
    width: 100%;
    padding-top: $boxSizeMargin * 1.4;
    padding-bottom: $boxSizeMargin * 1.4;
    border-bottom: 1px solid $borderGrey;
    font-size: $menuSize;
  }

  &__brand-service {
    flex-direction: column;
  }
}

@include media-breakpoint-up(lg) {
  .bottombar {
    .wysiwyg {
      width: auto;
    }

    .dropdown {
      border-bottom: none;

      &__toggle {
        display: none;
      }
    }

    .link-list {
      &__list {
        display: block;
      }
    }

    &__wrapper {
      flex-direction: row;
    }

    &__column {
      &__wrapper {
        padding-top: 0;
      }
    }

    &__categories {
      width: 33%;
    }
    &__brand-service {
      display: flex;
      flex-direction: row;
      width: 66%;
      &__brand,
      &__service {
        flex: 1;
      }
    }

    &__categories,
    &__brand-service {
      margin-bottom: 0;
    }

    &__brand-service {
      &__service {
        margin-top: 0;
      }
    }
  }
}
