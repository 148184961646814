.cart-infos {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  margin: 0 (-$boxSizeMargin * 0.5);

  &__item {
    flex: 1;
    margin: 0 $boxSizeMargin * 0.5;
    font-size: $extraSmallSize;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__value {
    margin-top: $boxSizeMargin * 0.25;
    font-weight: $bold;
    font-size: $smallSize;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label--sr-only {
    @include sr-only();
  }
}

@include media-breakpoint-up(lg) {
  .cart-infos {
    align-items: center;

    &__item {
      position: relative;
    }

    &__value {
      margin-top: 0;
    }

    &__label {
      width: 100%;
      position: absolute;
      top: -$boxSizeMargin * 2.5;
      text-align: center;
    }
  }
}
