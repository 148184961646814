.title-text {
  background-color: $white;
  text-align: center;
  width: 100%;
  &__title {
    font-family: $secondaryFontFamily;
    font-size: $extraLargeSize;
    font-weight: $semiBold;
  }
  &__text {
    font-family: $fontFamily;
    font-size: $smallSize;
    font-weight: $regular;
    color: $darkGrey;
    margin-top: 10px;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 40px;
    }
    &__text {
      font-size: $regularSize;
      max-width: 590px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
