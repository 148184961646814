.skeleton-loading {
  .image {
    background-color: $grey;
  }
  .text {
    &--animate {
      position: relative;
      overflow: hidden;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
  }
}
