.giftcards-dashboard {
    width: 60%;
    button {
        color: $white;
        background-color: $blue;
        border: 0px;
        float: inline-end;
        margin-top: 10px;
        &:hover, &:focus {
            background: $blue;
        }
    }
    .alert {
        background-color:$grey;
        padding: 0;
        margin: 0;
        color: $red;
        span {
            display: none;
        }
    }
}

.giftcards-result {
    margin-top: 4em;
    width: 50%;
    .main-field {
        font-weight: bold;
    }
    .second-field {
        border: solid 1px $pink;
        padding: 5px;
        border-radius: 20px;
        background-color: $pink;
        .price{
            font-weight: 500;
        }
    }
}

.giftcard-field {
    display: flex;
    place-content: space-between;
}
