.alert {
  background: $white;
  padding: $boxSizeMargin * 1.6;
  margin: $boxSizeMargin 0;

  &--success {
    color: $success;
  }
  &--error {
    color: $warning;
    background-color: #fff3ed99;
  }
  &--info {
    color: $shade04;
  }

  &__content {
    text-align: left;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &__message {
      margin-left: $boxSizeMargin;
    }
  }
}
