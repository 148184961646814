.widget-theme {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  .spinner__loading .icon {
    height: 100%;
  }
  .image--cover img {
    object-fit: cover;
  }
  &.loading-skeleton {
    .widget-theme__right {
      min-height: 500px;

      &__item {
        min-height: 250px;
        background-color: $grey;
      }
    }
    .widget-theme__left {
      background-color: $grey;
      min-height: 500px;
    }
  }
  .button {
    padding: 0;
  }
  .product-list__item {
    max-width: 100%;
    margin: 0;
    padding: 0;
    height: 290px;
  }
  &__left {
    width: 100%;
    order: 2;
    display: flex;
    .link {
      width: 100%;
    }
    img {
      height: 812px;
      width: 100%;
    }
  }
  &__right {
    width: 100%;
    &.product-item--grid {
      width: 100%;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;

    &__item {
      width: calc(50% - #{$boxSizeMargin});
      height: 395px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      &--media {
        .image   {
          height: 395px;
        }
        img {
          height: 395px;
          width: 100%;
        }
      }
      &--product {
        border: 1px solid $borderGrey;
        .product-item {
          border: none;
          margin: 0 auto;
        }
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: $boxSizeMargin * 2;
      }
    }
    &.left {
      order: 3;
      margin-top: $boxSizeMargin * 2;
    }
    &.right {
      order: 1;
      margin-bottom: $boxSizeMargin * 2;
    }
  }

  @media screen and (min-width: $xlWidth) {
    flex-direction: row;

    .product-list__item {
      height: 100%;
      padding: 10px;
    }
    &__left {
      width: 50%;
    }
    &__right {
      width: calc(50% - #{$boxSizeMargin * 2});
      &.left {
        margin-left: $boxSizeMargin * 2;
        margin-top: 0;
      }
      &.right {
        margin-right: $boxSizeMargin * 2;
        margin-bottom: 0;
      }
      &__item {
        min-height: 250px;
      }
    }
  }
  @media screen and (max-width: $xlWidth) {
    .product-list__item {
      height: auto;
      padding: 10px;
      .product-overview {
        position: initial;
        padding-top: 10px;
      }
    }
  }
  @media screen and (max-width: $mdWidth) {
    .widget-theme__right__item,
    .widget-theme__right__item--media img,
    .widget-theme__right__item--product .product-item,
    .widget-theme__right__item--media .image {
      height: auto;
    }
    .widget-theme__left img {
      height: auto;
    }
  }
}
