.box {
  background: $white;
  padding: $boxSizeMargin * 1.6;
  @include mobile-maxwidth();

  + .box {
    margin-top: $boxSizeMargin;
  }
}

@include media-breakpoint-up(lg) {
  .box {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
