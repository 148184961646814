.order-table {
    width: 100%;
    border-collapse: collapse;
    font-size: $extraSmallSize;
  
    thead {
      border-bottom: 2px solid $shade05;
      background-color: $grey;
      th {
        padding: $boxSizeMargin * 2 $boxSizeMargin;
        font-size: $extraSmallSize;
        font-weight: $bold;
        text-align: center;
      }
    }
  
    td,
    th {
      padding: 0.5rem 0;
    }
  
    tbody  {
        td {
            text-align: center;
            font-size: $smallSize;
        }
        tr {
            border-bottom: 1px solid $shade03;
        }
    }
  
    th {
      text-align: left;
    }
  
    margin-bottom: 1rem;
  }
  