.recap-card {
  &__title {
    margin: (0 - $boxSizeMargin) 0 0 0;
    padding: $boxSizeMargin 0 $boxSizeMargin;
    text-align: center;

    > :first-child {
      color: $realBlack;
      text-align: left;
      padding-bottom: $boxSizeMargin;
      margin: 0;
    }
  }

  &__description {
    text-align: center;
    margin-bottom: $boxSizeMargin;
  }

  &__children {
    padding-bottom: 0;

    fieldset {
      max-width: 100%;
    }

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
}
