.mini-cart-content {
  overflow-y: auto;
  color: $shade05;
  padding: $boxSizeMargin;

  &__title {
    border-bottom: 1px solid $shade03;
    padding-bottom: 0.5em;

    &__main {
      font-weight: bold;
      margin-right: 0.3em;
    }
  }

  &__message {
    text-align: center;
    padding-top: 0.3rem;
  }

  &__total {
    text-align: right;
    font-weight: bold;
    padding: $boxSizeMargin 0 0 0;

    &__main {
      text-transform: uppercase;
      color: $black;
      font-size: $extraLargeSize;
    }
  }

  &__empty {
    margin-top: 1em;
  }
}
