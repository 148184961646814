.image {
  &--not-found {
    background-color: $shade03;
    color: $brandPrimary;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
    text-align: center;
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
    text-align: center;
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
  &--cover img {
    object-fit: cover;
  }

  &--gradient {
    &:before {
      z-index: 1;
      content: "";
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0.3+0,0+100 */
      background: -moz-linear-gradient(
        left,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
    }
  }

  &--full,
  &--full-rounded {
    &.image--loading > *,
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
    }
    &.image--loading > * {
      height: 100% !important;
    }
    &.image--not-found {
      margin: 0 auto;
    }

    &::after {
      content: "";
      padding-top: 100%;
      display: block;
      height: 0;
    }
  }

  &--rounded,
  &--full-rounded {
    img {
      border-radius: 50%;
    }
  }
}
