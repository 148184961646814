.container .wysiwyg {
  background-color: $grey;
  & > div {
    @include container-maxwidth();
    padding-top: $boxSizeMargin * 2;
    padding-bottom: $boxSizeMargin * 2;
    background-color: white;
  }
}
.paginated-page {
  background-color: $grey;
  .wysiwyg {
    @include container-maxwidth();
    background-color: $white;
    padding-left: 0;
    padding-right: 0;
    .scope-push-container {
      > .push {
        width: 100%;
      }
    }
  }
}
