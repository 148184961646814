.error-component {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0 auto;
    background: $pink;
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
        font-family: $secondaryFontFamily;
        font-size: 4rem;
        font-weight: $semiBold;
    }
    > img {
        display: none;
    }
    &__text {
        width: 100%;
        padding: $boxSizeMargin * 8;
        box-sizing: border-box;
        .button--primary {
            margin-right: 2rem;
          }
    }
    @media screen and (min-width: $menuBreakpoint) {
        flex-direction: row;
        > img {
            display: block;
            width: 50%;
        }
        &__text {
            width: 50%;
        }
    }
}