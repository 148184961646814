$menuPopinWidth: 100%;

.menu-popin {
  width: $menuPopinWidth;
  height: auto;
  min-width: 30ch;
  background-color: $white;
  border-left: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 12;
  height: 100vh;
  background: white;
  &--loading {
    .navigation-menu__item{
      color: $darkGrey;
      cursor: not-allowed;
    }
  }
  .navigation-menu__item-list__gender-menu {
    .navigation-menu__item {
      padding: 20px;
      text-transform: capitalize;
      font-weight: $extraBold;
      font-family: $secondaryFontFamily;
      font-size: $largeSize;
      border-top: 1px solid $shade03;
      border-left: 1px solid $shade03;
      border-bottom: 1px solid $shade03;
      &--active {
        border-bottom: 3px solid $white;
        color: $black;
        &:after {
          display: none;
        }
      }
      &--loading {
        cursor: not-allowed;
        color: $darkGrey !important;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          z-index: 2;
          color: black;
          display: block;
          width: 100%;
          height: 100%;
          background-image: url("data:image/svg+xml,%0A%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23000' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &:first-child {
        border-left: none;
      }
    }
  }
  &--subcat {
    .navigation-menu__item-list__gender-menu {
      display: none;
    }
    h4 {
      font-weight: $bold;
      font-size: $mediumSize;
    }
    .navigation-menu__item .labelled-icon .labelled-icon__icon {
      display: none;
    }
  }
  .navigation-menu__item:focus {
    border: none;
  }
  .navigation-menu__item--see-all h4 {
    color: $darkGrey;
  }
  .navigation-menu__item__section-title {
    color: $shade04;
    font-size: $giantSize;
    font-weight: $regular;
    margin-top: 15px;
  }
  .navigation-menu__item--tab-left {
    padding-left: 15px;
  }
  &__icons {
    @include container-maxwidth();
    width: 100%;
    min-height: 5.4rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $boxSizeMargin * 1.1;
    padding-bottom: $boxSizeMargin * 1.1;
    border-bottom: 1px solid $borderGreyLight;
    @media screen and (min-width: $mdWidth) and (max-width: $menuBreakpoint) {
      small {
        font-size: $regularSize;
        font-weight: 600;
      }
    }
  }

  &__title {
    font-size: $extraLargeSize;
    font-weight: $semiBold;
    font-family: $secondaryFontFamily;
    flex: 1;
    text-align: center;
  }
  .labelled-icon__content > :first-child {
    margin-right: 0;
  }

  &__navigation {
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-x: hidden;
    .navigation {
      @include container-maxwidth();
      border-bottom: 1px solid $borderGreyLight;
    }
  }

  &__links {
    margin: 0 $boxSizeMargin * 1.6;
    @include heading(1.6rem);
    font-weight: $semiBold;
    line-height: 1.33;
    margin-top: 1em;
    margin-bottom: 1em;
    &__wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      p {
        padding: 1rem 0;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      small {
        line-height: 2rem;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container-maxwidth();
    box-sizing: border-box;
    width: 100%;
    background-color: $pink;
  }

  &__phone {
    display: flex;
    align-items: center;
    height: 5rem;

    .icon {
      display: flex;
    }

    .body small {
      font-size: $smallSize;
    }
  }

  .select__input .icon {
    display: none;
  }

  .select__input > select {
    font-size: $smallSize;
    text-transform: uppercase;
    color: $brandPrimary;
    font-weight: $bold;
  }

  img.logo {
    height: 2.6rem;
    width: auto;
  }

  .lnr-cross {
    font-size: 1.9rem;
    font-weight: bold;
  }
}
