.product-overview {
  position: absolute;
  bottom: $boxSizeMargin * 1.8;
  right: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: $white;
  padding-top: $boxSizeMargin * 2.4;

  &__name {
    font-size: $smallSize;
    font-weight: $bold;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $black;
    text-decoration: none;
    &__subline {
      font-size: $mediumSize;
      display: block;
      font-weight: $regular;
      font-family: $fontFamily;
      color: $darkGrey;
    }
  }

  &__price {
    font-size: $smallSize;
    color: $darkGrey;
    
    
    text-decoration: none;
    display: inline-block;
    &--hidden {
      visibility: hidden;
    }

    .product-price {
      display: inline-block;
    }
  }

  &__sizes {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .product-item {
    &:hover {
      .product-item__overview {
        position: absolute;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        left: 0;
        width: 100%;
        z-index: 2;
        box-sizing: border-box;
        .product-overview {
          bottom: 0;
        }
      }
      .product-item__add-to-cart {
        position: relative;
        bottom: 0;
        margin-bottom: 20px;
      }
      & .product-overview {
        position: relative;
        background: transparent;
        &__sizes {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: $boxSizeMargin * 1.8;

          &__size {
            font-size: $extraSmallSize;
            margin-right: $boxSizeMargin * 0.8;
          }
        }
      }
    }
  }
  .product-overview {
    bottom: $boxSizeMargin * 2.4;
  }
}
