@import "./Radio/Radio";

input:not([type="radio"]),
select,
textarea {
  border: 1px solid $borderGrey;
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
  font-size: $smallSize;
  font-family: $fontFamily;
  color: $black;
  width: 100%;

  &[disabled] {
    color: $inputBorderColor;
  }

  &::placeholder {
    color: $darkGrey;
  }
}

.input--short {
  width: 7ch;
}

.input--invalid,
.textarea--invalid {
  border: 1px solid $red;
}

.input--valid,
.textarea--valid {
  border: 1px solid green;
}

input:not([type="radio"]),
select {
  padding: 0 $boxSizeMargin * 1.6;
  height: 4rem;
}

input[type="radio"] {
  height: auto;
  width: auto;
  max-width: none;
  margin-right: $boxSizeMargin;
  margin-top: auto;
  margin-bottom: auto;
}

textarea {
  padding: $boxSizeMargin * 1.6;
}

.input-wrapper--invisible {
  input,
  select,
  textarea {
    border: 0;
  }
}

.input-wrapper__error {
  color: $orange;
  margin-top: $boxSizeMargin * 0.5;
  margin-right:30px
}

.input-wrapper__help {
  font-size: $tinySize;
  margin-top: $boxSizeMargin * 0.5;
}
.password-wrapper {
  position: relative;
  > .button--icon {
    position: absolute;
    top: 3px;
    padding: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
  }
}

.input-error {
  color:$orange;
}