.total {
  font-size: $smallSize;
  background-color: $white;

  &__title {
    background-color: $black;
    color: $white;
    padding: $boxSizeMargin * 0.5 $boxSizeMargin * 1.6;
    font-size: $extraSmallSize;
    text-align: right;

    &__action {
      margin-left: $boxSizeMargin;

      .button--icon:hover,
      .button--icon:focus {
        color: $white;
      }
    }

    .icon {
      transition: transform 0.3s ease;
    }
  }

  .total-item {
    max-width: 220px;
  }
  .total-item__label {
    text-align: left;
  }

  .total-item__qty {
    text-align: left;
    color: $darkGrey;
  }

  &--visible {
    .total__title {
      .icon {
        transform: rotate(180deg);
      }
    }

    .total__recap {
      .recap-table {
        &__line {
          display: flex;
          &__title {
            align-items: center;
          }
        }
      }
    }
  }

  &__recap {
    .recap-table {
      &__line {
        display: none;
        padding: $boxSizeMargin * 0.5 $boxSizeMargin * 1.6;

        .price {
          margin-left: $boxSizeMargin * 1.6;
          text-align: right;
        }

        &--final {
          display: flex;
          margin-top: 0;
        }
        &--total-excl-tax, &--tax {
          display: none;
        }
      }
    }

    > .recap-table {
      > .recap-table__line {
        border-top: 1px solid $borderGrey;
        padding: $boxSizeMargin * 0.8 $boxSizeMargin * 1.6;

        &--final {
          border-top: none;
          padding: $boxSizeMargin $boxSizeMargin * 1.6;
          background-color: $pink;
          font-size: $regularSize;
        }
        
      }
      > .recap-table {
        > .recap-table__line + .recap-table__line {
          margin-top: $boxSizeMargin * 0.5;
        }
      }
      .recap-table:not(:first-child) {
        & > .recap-table__line:first-child {
          border-top: 1px solid $borderGrey;
        }
        .recap-table__line:not(:first-child) {
          margin: 0;
        }
        .recap-table__line--standalone {
          padding: 0;
          p {
            font-size: $extraSmallSize;
          }
        }
        .recap-table__line--nice {
          align-items: center;
          font-size: $extraSmallSize;
        }
      }
    }
  }
}

// Checkout total recap
.multistep__recap  {
  overflow: auto;
  @include media-breakpoint-up(lg) {
    overflow: visible;
  }
  .total {
  .coupon-code {
    button.button--link,
    &__input {
      display: none;
    }
  }
  .coupon-code:not(.coupon-code--withcode) {
    display: flex;
    justify-content: space-between;
    form {
      display: inline-block;
    }
  }
}
}

@include media-breakpoint-up(lg) {
  .total {
    width: 340px;

    &__title {
      padding: $boxSizeMargin;
      text-align: center;
      font-size: $veryLargeSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;

      &__action {
        display: none;
      }
    }
    &__recap {
      .recap-table {
        &__line {
          display: flex;
          &--total-excl-tax, &--tax {
            display: none;
          }
        }
      }

      > .recap-table {
        > .recap-table__line {
          &--final {
            padding: $boxSizeMargin * 1.6;
          }
        }
      }
    }
  }
}
