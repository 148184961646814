.cart-content {
  &__wrapper {
    background-color: $grey;
  }
  &__crossells {
    padding: $boxSizeMargin * 4 0 $boxSizeMargin 0;
    background-color: $white;
    @include container-maxwidth(0);
    .product-slider-desktop__details__title {
      width: 320px;
    }
  }
}
