.three-images {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  flex-wrap: wrap;
  h3 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: $secondaryFontFamily;
    font-weight: $semiBold;
    font-size: $extraLargeSize;
  }
  &__image {
    max-width: 100%;
    width: 288px;
    margin: 0 auto;
    margin-bottom: 24px;
    .image {
      height: 320px;
      width: 100%;
      img {
        max-height: 100%;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
    h3 {
      margin-bottom: 16px;
    }
    &__image {
      width: 464px;
      max-width: 100%;
      &:nth-child(2) {
        padding: 0 10px;
      }
      .image {
        height: auto;
      }
    }
  }
}
