@use "sass:math";

.radio {
  display: flex;
  align-items: center;

  > label {
    flex: 1;
    text-align: left;
  }
}

.radio + .radio {
  margin-top: math.div($boxSizeMargin, 4);
}
