.account-navigation {
  padding-left: 0;
  margin: 0;
  min-width: 27rem;

  .select__input__button-wrapper {
    justify-content: center;
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .select--valid,
  .select--invalid {
    .select__input {
      border-color: $borderGrey;
      > :last-child {
        color: inherit;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkGrey;
    text-decoration: none;
    padding: $boxSizeMargin * 1.6 $boxSizeMargin * 3.2 $boxSizeMargin * 1.6
      $boxSizeMargin * 1.6;

    &:disabled,
    &--disabled {
      pointer-events: none;
      color: $blackDisabled;
    }

    &--active,
    &:hover,
    &:focus {
      font-weight: bold;
      background-color: $brandSecondary;
      color: $black;

      + .account-navigation__item {
        border-top: none;
      }
    }
  }
}
