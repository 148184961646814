@font-face {
  font-family: Montserrat;
  src: url("./Montserrat/Montserrat-Regular.priority.woff2") format("woff2"),
    url("./Montserrat/Montserrat-Regular.woff") format("woff"),
    url("./Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  src: url("./Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("./Montserrat/Montserrat-500.priority.woff2") format("woff2"),
    url("./Montserrat/Montserrat-500.woff") format("woff"),
    url("./Montserrat/Montserrat-500.ttf") format("truetype");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("./Montserrat/Montserrat-SemiBold.priority.woff2") format("woff2"),
    url("./Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("./Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  src: url("./Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("./Montserrat/Montserrat-Bold.priority.woff2") format("woff2"),
    url("./Montserrat/Montserrat-Bold.woff") format("woff"),
    url("./Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url("./Montserrat/Montserrat-800.priority.woff2") format("woff2"),
    url("./Montserrat/Montserrat-800.woff") format("woff");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Muli;
  font-weight: 300;
  src: url("./Muli/Muli-Light.priority.woff2") format("woff2"),
    url("./Muli/Muli-Light.woff") format("woff"),
    url("./Muli/Muli-Light.ttf") format("truetype");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Muli;
  src: url("./Muli/Muli-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Muli;
  src: url("./Muli/Muli-Regular.priority.woff2") format("woff2"),
    url("./Muli/Muli-Regular.woff") format("woff"),
    url("./Muli/Muli-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Muli;
  src: url("./Muli/Muli-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Muli;
  font-weight: 700;
  src: url("./Muli/Muli-Bold.priority.woff2") format("woff2"),
    url("./Muli/Muli-Bold.woff") format("woff"),
    url("./Muli/Muli-Bold.ttf") format("truetype");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Muli;
  src: url("./Muli/Muli-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: block;
}
