.four-products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 575px;
  margin: 0 auto;
  &.loading-skeleton {
    .product-item {
      position: relative;
      background-color: $grey;
    }
  }
  .product-list__item {
    max-width: 100%;
    margin: 0;
    height: auto;
  }
  .product-overview {
    position: initial;
    padding-top: 10px;
  }
  .product-item {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
    &.product-push {
      width: 100%;
    }
  }
  @media screen and (min-width: $containerMaxWidth) {
    flex-wrap: nowrap;
  }
  @media screen and (min-width: $lgWidth) {
    .product-list__item {
      height: 384px;
    }
    product-overview {
      position: absolute;
    }
    max-width: initial;
    .product-item {
      width: 25%;
      margin: 1rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.product-push {
        width: 25%;
      }
    }
  }
}
