$miniCartDefaultSpace: 0.5em;
$miniCartDefaultLeftMargin: 0.5em;

.mini-cart {
  position: relative;
  z-index: 3;
  border-left: 1px solid $white;
  .mini-cart-items {
    max-height: 250px;
    overflow: auto;
  }
  .spinner__loading .icon {
    margin-top: 40px;
  }

  &__container {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 100%;
    top: calc(100% + #{$boxSizeMargin * 0.5});
    width: 24em;
    min-width: 30ch;
    max-width: 98vw;
    max-width: calc(100vw - #{$boxSizeMargin * 0.25});
    max-height: 85vh;
    overflow-y: auto;
    border: 1px solid $shade03;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 8px 15px 0 rgba($shade05, 0.05);
    .button {
      width: 80%;
    }
    
  }
  .mini-cart-item {

&__details {
  display: flex;
  flex-direction: column;
  padding-left: $boxSizeMargin * 2;
  font-style: $smallSize;
  .link {
    font-size: $smallSize;
    display: block;
    width: 100%;
    text-align: left;
  }
  .remove {
    color: $blue;
    cursor: pointer;
  }
  > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: $boxSizeMargin;
  }
}
  }
  .mini-cart-content {
    &__total__main {
      text-align: left;
      display: flex;
      justify-content: space-between;
      font-size: $regularSize;
      font-family: $secondaryFontFamily;
      .price {
        text-align: right;
      }
      margin-bottom: $boxSizeMargin * 2;
    }
  }
}
