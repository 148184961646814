.checkbox,
.checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  > label {
    flex: 1;
    text-align: left;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;

    &:checked + label:before {
      border: 1px solid $borderGrey;
    }

    &:checked + label:after {
      content: "\2713";
      color: $brandPrimary;
      font-size: 20px;
      top: calc(50% - 13px);
      transform: scale(1);
    }
  }

  label {
    position: relative;
    padding-right: $boxSizeMargin * 3;
    display: flex;
    align-items: center;
  }

  label:before,
  label:after {
    content: "";
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 0;
    top: calc(50% - 0.875rem);
  }

  label:before {
    border: 1px solid $borderGrey;
  }

  label:after {
    transform: scale(0);
  }

  &--darker {
    input[type="checkbox"] {
      & + label:before {
        border: 1px solid $darkGrey;
      }
      &:checked + label:before {
        border: 1px solid $darkGrey;
      }
    }
  }

  &--inverted {
    label {
      padding-right: 0;
      padding-left: $boxSizeMargin * 3;
    }

    label:before,
    label:after {
      content: "";
      left: 0;
    }
  }
}
