.size-availables {
  display: none;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  font-size: $extraSmallSize;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-family: $secondaryFontFamily;
  font-weight: 500;
  min-height: 76px;
  align-items: center;
  &--skeleton {
    .size {
      background-color: $grey;
      width: 50px;
      height: 18px;
      display: inline-block;
      position: relative;
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
  }
  .size {
    margin-right: 8px;
    color: $black;
    &--out-stock {
      color: $darkGrey;
      text-decoration: line-through;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.product-item--withcolor {
  &:hover {
    .size-availables {
      display: flex;
    }
  }
}
.product-item {
  &:hover {
    .size-availables {
      display: flex;
    }
  }
}
