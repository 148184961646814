.chronopost {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2em;

  &__modal {
    padding-bottom: 2em;
  }
  &__title {
    text-align: center;
  }
  &__form {
    text-align: center;
    margin-top: 2em;
    select {
      appearance: auto;
      cursor: pointer;
    }
    &--wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .hidden {
      display: none !important;
    }
    &__selected {
      font-weight: bold;
      font-size: 1.8rem;
    }
    .button {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
    &__selected {
      margin-bottom: 1.2rem;

      &__address {
        font-style: italic;
      }
    }
    label {
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;
    }

    .chronopost__form__input25 {
      width: 50%;
      display: inline-block;
    }
    &__inputmargin {
      padding-left: 20px;
      box-sizing: border-box;
      &:nth-child(1) {
        padding-left: 0;
      }
      &:last-child {
        padding-left: 0;
      }
    }
  }
  .map {
    flex: 2;
    width: 400px;
    height: 400px;
    max-width: 94%;
    border: 1px solid $shade03;
    margin: 0.5em;

    &__info {
      &__name {
        font-weight: bold;
      }

      &__address {
        font-style: italic;
      }
    }
  }
  &__pickup-points {
    flex: 1;
    height: 400px;
    max-width: 94%;
    overflow-y: scroll;
    padding: 0;
    margin: 0.5em;
    border: 1px solid $shade03;

    &__pickup-point {
      border-bottom: 1px solid $shade03;
      padding: 0.5em;
      cursor: pointer;

      &.active {
        background-color: $shade02;
      }
      &:last-child {
        border-bottom: none;
      }

      &__name {
        font-weight: bold;
      }

      &__address {
        font-style: italic;
      }

      &__schedule {
        font-size: $extraSmallSize;

        &__day {
          span {
            font-weight: bold;
            margin-right: 0.25em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .chronopost {
    flex-direction: row;
    &__form {
      .chronopost__form__input25 {
        width: 25%;
      }
    }
    &__title {
      margin-top: 0;
    }
  }
}
