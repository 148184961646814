.product-slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: calc(#{$productMaxWidth} + #{$boxSizeMargin} * 1.6 * 2);
  padding: 2 * $boxSizeMargin 0;

  .swipe {
    width: 100%;
  }
  .product-item {
    margin: $boxSizeMargin $boxSizeMargin * 1.6;
  }

  &__prevnext {
    display: flex;
    max-width: $productMaxWidth;
    margin: 0 $boxSizeMargin * 1.6;
    align-items: center;

    &__before,
    &__after {
      border-radius: 50%;
      color: $white;
      display: flex;
      justify-content: center;
      align-content: center;

      .button {
        padding: 0;
        height: $boxSizeMargin * 4;
        width: $boxSizeMargin * 4;
      }
    }

    &__before,
    &__after {
      margin-left: auto;
    }

    &__position {
      position: relative;
      border-top: 1px solid $borderGrey;
      height: 0px;
      margin: 0 $boxSizeMargin * 3;
      flex: 1;

      &__current {
        position: absolute;
        content: "";
        top: -1px;
        border-top: 1px solid $black;
      }
    }
  }

  &__cta,
  &__title {
    max-width: $productMaxWidth;
    text-align: center;
  }

  &__title {
    margin: 0 $boxSizeMargin * 1.6;
    > * {
      margin: 0;
    }
  }

  &__cta {
    margin: $boxSizeMargin $boxSizeMargin * 1.6;

    > .button {
      font-size: $smallSize;
    }

    .lnr {
      margin-left: $boxSizeMargin;
    }
  }
}

.product-slider-desktop {
  margin: $boxSizeMargin * 2 auto;

  &.loading-skeleton {
    .product-item {
      position: relative;
      background-color: $white;
    }

    .product-item__image {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 280px;
      background-color: $grey;
    }
    .product-slider-desktop__details__title {
      &--name {
        display: inline-block;
        width: 100%;
        height: 50%;
        position: relative;
        overflow: hidden;
        background-color: $borderGreyLight;
        border-radius: 5px;
        &::after {
          @keyframes progress {
            0% {
              transform: translate3d(-100%, 0, 0);
            }
            100% {
              transform: translate3d(100%, 0, 0);
            }
          }
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            $grey,
            lighten($borderGreyLight, 7%),
            $grey
          );
          animation: progress 2s ease-in-out infinite;
        }
      }
    }
    @media screen and (max-width: $menuBreakpoint) {
      .product-item:nth-child(1),
      .product-item:nth-child(2),
      .product-item:nth-child(3) {
        display: none;
      }
      .product-item {
        width: 288px;
        margin: 20px auto 0 auto;
      }
      .product-item__image {
        height: 210px;
      }

      .product-slider-desktop__details__title {
        height: auto;
        text-align: center;
        width: 100%;
        &--name {
          height: 100%;
          width: 50%;
        }
      }
      .product-slider-desktop__details {
        padding-left: 0;
      }
      .product-slider-desktop__content__items {
        margin-left: 0;
      }
    }
    .product-item__text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 70px;
    }
    .product-item__title {
      width: 50%;
      height: 30%;
      position: relative;
      overflow: hidden;
      background-color: $borderGreyLight;
      border-radius: 5px;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
    .product-item__price {
      width: 60%;
      height: 25%;
      position: relative;
      overflow: hidden;
      background-color: $borderGreyLight;
      border-radius: 5px;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
  }
  h2.h4 {
    font-size: 2.4rem;
  }

  &__details {
    display: flex;
    padding-left: $boxSizeMargin * (9.8 + 2.4);

    &__title {
      width: auto;
      height: $boxSizeMargin * 6;
      margin-top: -4px;
      white-space: pre-line;
      padding-right: $boxSizeMargin * 2;
      display: flex;
      align-items: center;
      > * {
        margin: 0;
      }
    }

    &__cta {
      display: flex;
      align-items: center;
      > .button {
        font-size: $smallSize;
      }

      .lnr {
        margin-left: $boxSizeMargin * 1.6;
      }
    }
  }

  &__content {
    position: relative;
    overflow: hidden;

    &__items {
      transition: transform 0.3s ease;
      margin-left: $boxSizeMargin * (9.8 + 2.4);

      display: flex;

      > *:first-child {
        margin-left: -$boxSizeMargin * 1.2;
      }

      .product-item {
        margin: $boxSizeMargin * 1.2;
      }

      .product-price .price {
        font-weight: $regular;
      }
    }

    .product-slider__prevnext__before,
    .product-slider__prevnext__after {
      position: absolute;
      z-index: 1;
      top: calc(50% - #{$boxSizeMargin} * 2);
      .lnr {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .lnr-chevron-right:before {
        font-weight: 800;
        font-size: $tinySize;
      }
      .icon {
        justify-content: center;
      }
    }

    .product-slider__prevnext__after {
      right: 0;
    }
  }
}
