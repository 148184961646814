.breadcrumb {
  @include container-maxwidth();
  padding-top: $boxSizeMargin * 1;
  padding-bottom: $boxSizeMargin * 1.7;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: $white;
  @include mobile-maxwidth();
  margin: 0 auto;
  .rating-summary {
    display: flex;
    margin-bottom: $boxSizeMargin * 2;
    align-items: center;
    cursor: pointer;
    &__value {
      font-size: $extraSmallSize;
      color: $shade04;
      margin-left: $boxSizeMargin;
    }
    &__count {
      font-size: $extraSmallSize;
      color: $shade04;
      margin-left: $boxSizeMargin;
      > span {
        margin-right: 2px;
      }
    }
  }
  .product-slider-desktop {
    width: 100%;
    margin-top: $boxSizeMargin * 4;
  }
  &__open-stock-warning .icon {
    display: none;
  }
  &__name {
    display: flex;
    flex-direction: column;
    .h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
    &__subtitle {
      font-size: $mediumSize;
      display: block;
      font-weight: $regular;
      font-family: $fontFamily;
      color: $darkGrey;
      height: auto;
    }
  }
  .similar-color {
    border: 1px solid $borderGrey;
    padding: 15px 12px;
    margin-bottom: 12px;
    margin-top: 5px;
    box-sizing: border-box;
    .similar-color__color-count {
      text-align: right;
    }
    .form-item__label small {
      display: none;
    }
    &__thumbnails {
      flex-wrap: wrap;
    }
  }
  &__wrapper {
    width: 100%;
  }
  .product-slider-desktop {
    width: 100%;
    margin-top: $boxSizeMargin * 4;
  }
  .spinner__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    background-color: $grey;
  }
  &__details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: $boxSizeMargin * 3 * 0.5 0;
  }

  &__price {
    font-size: $smallSize;
    .product-price {
      font-size: 2rem;
    }
    &--hidden {
      display: none;
    }
  }

  &__gallery {
    width: 830px;
    max-width: 100%;
    align-self: flex-start;
    margin: 0 auto;
    margin-top: $boxSizeMargin * 2;
    display: flex;
    border-bottom: 1px solid $borderGrey;
    position: relative;
    .stickers {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    .responsive-image--loading {
      width: 100%;
      height: 100%;
    }
    > .image {
      position: relative;
      height: 613px;
      width: 708px;
      box-sizing: border-box;
      border: 1px solid $borderGrey;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__slider {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1em;
      display: flex;
      flex-direction: column;
      margin-right: 0px;
      &--prev,
      &--next,
      &__image {
        padding: 0 0.5em;
      }
      &__image {
        position: relative;
        width: 98px;
        box-sizing: border-box;
        height: 102.3px;
        padding: 4px;
        max-height: 10em;
        border-top: none;
        border: 1px solid $borderGrey;

        &--active {
          border-color: $black;
        }
      }
      &--prev,
      &--next {
        position: absolute;
      }
      &--prev {
        top: 7px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);
      }
      &--next {
        bottom: 7px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
      }
    }

    &__image {
      box-sizing: border-box;
      width: 100%;
      max-height: calc(100vw - 2 * #{$boxSizeMargin});
      padding-bottom: 2 * $boxSizeMargin;
    }

    &__position-wrapper {
      position: absolute;
      bottom: $boxSizeMargin * 2.5;
      z-index: 2;
      width: 100%;
    }

    &__position {
      position: relative;
      border-top: 1px solid $borderGrey;
      height: 0px;
      margin: 0 $boxSizeMargin * 3;
      flex: 1;
      margin: 0 auto;
      width: 50%;

      &__current {
        position: absolute;
        content: "";
        top: -1px;
        border-top: 1px solid $black;
      }
    }

    &__previous {
      z-index: 1;
      position: absolute;
      left: -$boxSizeMargin * 0.6;
      top: calc(50% - #{$boxSizeMargin} * 3.6);

      .button {
        background-color: $black;
      }
      .icon {
        transform: rotate(90deg);
      }
    }

    &__next {
      z-index: 1;
      position: absolute;
      right: -$boxSizeMargin * 0.6;
      top: calc(50% - #{$boxSizeMargin} * 3.6);
      .button {
        background-color: $black;
      }
      .icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__synthesis {
    max-width: 100%;
    background: $white;
    border-radius: 5px;
    padding-top: $boxSizeMargin * 2;
    margin: 0 auto;
    box-sizing: border-box;
    .modal{
      &__content{
        display: none;
      }
    }
  }
  .tab-view__content {
    padding: 0;
  }
  &__additional-informations {
    overflow: hidden;
    width: 100%;
    &__tab-view {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      .wysiwyg > div {
        padding: 0 !important;
      }
    }

    .product__tab-content {
      background: $white;

      &__text {
        padding: $boxSizeMargin * 2;
      }

      &__image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__add-to-cart {
    width: auto;
    margin: 0 auto $boxSizeMargin * 2 auto;

    .subscribe-to-in-stock-alert {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .add-to-cart__qty {
      display: none;
    }
  }

  &__out-of-stock {
    font-size: $mediumSize;
    color: $shade05;
  }

  &__tab-content {
    &__image img {
      width: 100%;
    }
  }
}
@include media-breakpoint-up(menuBreakpoint) {
  .product {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@include media-breakpoint-up(xl) {
  .product {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0;
    @include container-maxwidth();
    background-color: $white;

    &__details {
      margin: 0;
    }
    &__name {
      &__subtitle {
        height: 42px;
      }
    }

    .similar-color {
      border: none;
      padding: 0;
      margin-bottom: 0;
      .form-item__label small {
        display: block;
      }
      .similar-color__color-count {
        text-align: initial;
      }
    }
    &__wrapper {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      box-sizing: border-box;
      width: 100%;
      margin-top: 0;
    }

    &__details__wrapper {
      display: flex;
      margin-right: 1.2rem;
      .similar-color .product-slider-desktop {
        margin-top: $boxSizeMargin;
      }
      &--left {
        flex: 1;
        width: 100%;
      }
    }

    &__gallery {
      min-width: 250px;
      margin: 0 $boxSizeMargin * 2.4 $boxSizeMargin * 2 0;
      position: sticky;
      top: 91px;
      border-bottom: none;
      &__previous,
      &__next,
      &__position-wrapper {
        display: none;
      }
      &__image {
        height: 474px !important;
      }
      .image img {
        max-width: initial;
      }
      .responsive-image--loading img {
        object-fit: cover;
      }
      &__slider {
        position: relative;
        max-width: none;

        &__image:hover {
          cursor: pointer;
          border-bottom-color: $black;
        }

        &--prev,
        &--next {
          position: inherit;
          padding: 0;
          position: absolute;
          z-index: 1;
          margin: 0;

          .button {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $black;
            .lnr {
              font-size: 1.5rem;
              font-weight: bold;
            }
          }
        }

        &--prev {
          top: 7px;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(90deg);
        }

        &--next {
          bottom: 7px;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(90deg);
        }
      }
    }
    &__synthesis {
      max-width: 464px;
      margin: 0;
      padding: 0;
      margin-left: 61px;
      width: calc(100% - 920px);

      > * {
        width: 100%;
      }

      .configurator {
        max-width: 100%;
        margin: 15px auto 0 auto;
      }

      .select__input > :last-child {
        padding: 0 0 0 $boxSizeMargin * 1.6;
      }
    }

    &__delivery-infos {
      flex: 1;
      margin-left: $boxSizeMargin * 1.2;
    }

    &__add-to-cart {
      max-width: 100%;
    }

    &__additional-informations {
      &__tab-view {
        width: 100%;
        padding: 0;
      }

      .product__tab-content {
        display: flex;

        &__text {
          padding: 17px 0;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .product {
    &__container {
      width: 100%;
      height: 620px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .product {
    &__container {
      height: 600px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .product {
    &__container {
      height: 95vw;
    }
  }
}