.faq {
  margin-bottom: 50px;
  &__header {
    position: relative;
    padding: 0;
    @include container-maxwidth();
    background-color: $white;
    margin-bottom: 0;
    h1 {
      position: absolute;
      left: 5%;
      top: 0;
      bottom: 0;
      margin: auto;
      color: $white;
      z-index: 2;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
  }
  &__content {
    background: $grey;
    @include container-maxwidth();
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__answers__title {
    font-size: $regularSize;
  }
  .button {
    border-radius: 0;
    text-align: left;
    font-size: $smallSize;
    color: $darkGrey;
    padding: 0.95rem 2.4rem;
    margin-bottom: 20px;
    font-family: $fontFamily;

    &--thirdly {
      color: $black;
    }
  }
  &__answers {
    padding: 0 20px;
  }
  &__answers__item {
    background-color: $white;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border: 1px solid $borderGrey;
    
    .button {
      margin-bottom: 0;
      color: $black;
      font-family: $fontFamily;
    }
    &__button {
      font-size: $smallSize;
      font-weight: 600;
      width: 100%;
      border: none;
      padding: 10px 20px 0 20px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $fontFamily;

      &:focus {
        outline: none;
      }
    }
    &__answer {
      display: inline-block;
      padding: 0 20px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    &:not(&--expanded) {
      .faq__answers__item__answer {
        display: none;
        font-size: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        /* fade out, then shrink */
        transition: opacity 0.25s, font-size 0.5s 0.25s, margin 0.5s 0.25s,
          padding 0.5s 0.25s;
      }
    }
    &--expanded {
      .faq__answers__item__answer {
        transition: font-size 0.25s, margin 0.25s, padding 0.25s,
          opacity 0.5s 0.25s;
        width: 100%;
        box-sizing: border-box;
        border-left: 4px solid $darkGrey;

      }
    }
  }
  &__categories {
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: 20px;
    font-family: $fontFamily;
  }
  .react-select.select--light .select__single-value {
    padding: 20px;
    justify-content: center;
    width: 100%;
    font-weight: $bold;
    font-family: $fontFamily;
  }
  .react-select .select__menu {
    width: 100%;
  }
  .react-select .select__indicators {
    padding-right: 40px;
  }
  .react-select .select__placeholder,
  .react-select .select__single-value {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: $fontFamily;
  }
  @media screen and (min-width: $containerMaxWidth) {
    &__header {
      margin-bottom: 50px;
      padding: 0;
    }
    &__content {
      flex-direction: row;
    }
    &__categories {
      width: 274px;
      margin-right: 32px;
      background-color: $white;
    }
    &__answers {
      width: 1042px;
      padding: 0;
    }
    &__answers__item {
      border: 0;
      padding-bottom: 20px;
      &__button {
        padding: 30px 50px 10px 50px;
      }
      &__answer {
        padding: 0 50px;
      }
    }
  }
  @media screen and (max-width: #{$maxWidthMobile}) {
    &__header {
      padding: 0;
    }
  }
}
