.paginator {
  display: flex;
  justify-content: center;
  margin: $boxSizeMargin auto 0;
  background: $brandSecondary;

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: auto;
    padding: $boxSizeMargin 0;
  }

  li {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    align-items: center;
    justify-content: center;

    a {
      color: $hoverableColor;
      text-decoration: none;
      transition: background-color 0.3s;
    }

    &:hover {
      font-weight: $bold;
    }

    &.active {
      background-color: $white;
      border-radius: 3.2rem;

      a {
        color: $black;
        font-weight: $bold;
      }
    }

    &.disabled a {
      color: $shade04;
      cursor: default;
    }

    &.disabled a:hover {
      background-color: inherit;
    }

    &.disabled:hover a {
      border-bottom: 0px $hoverableColor solid;
    }

    &.next a {
      padding-right: 0;
      color: $black;
    }
    &.previous a {
      padding-left: 0;
      color: $black;
    }
  }
}

@include media-breakpoint-up(lg) {
  .paginator {
    margin: 0;
    background-color: $grey;
    justify-content: initial;

    &__wrapper {
      background-color: $pink;
      @include container-maxwidth();

      display: flex;
      justify-content: flex-end;
      width: 100%;

      ul {
        margin: 0;
        padding: $boxSizeMargin * 2 0;
      }
    }
  }
}
