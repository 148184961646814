.category-sorting {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
 
  #sort {
    font-size: $regularSize;
    width: 100%;
  }
  .button {
    font-size: $regularSize;
  }
  > div,
  > button {
    flex: 1;
  }
  .labelled-icon__content {
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
  }
  &__button + &__select {
    margin-left: $boxSizeMargin;
  }

  .select__input {
    border: none;
  }

  .select__input__button-wrapper {
    font-weight: $regular;
    font-size: $extraSmallSize;
    font-family: $fontFamily;
  }

  .select__input > :last-child {
    padding: 0 $boxSizeMargin * 1.6 0 0;
  }
  .select--placeholder .select__input > :last-child {
    color: $black;
  }

  .button--ghost {
    margin-left: $boxSizeMargin;
    color: $black;
    font-weight: $regular;
    padding: 0;

    .labelled-icon {
      width: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    .select__control {
      width: 105px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .category-sorting {
    margin: $boxSizeMargin * 0.5 0;
    justify-content: flex-end;
    .select {
      width: auto;
    }
  }
}
@media screen and (min-width: $containerMaxWidth) {
  .category-sorting {
    #sort {
      font-size: $extraSmallSize;
      width: 80px;
    }
  }
}