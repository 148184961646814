.imagequote {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  .spinner {
    margin-top: 40px;
  }

  &__image {
    &--reduce {
      padding: 20px;
    }
    &--full {
      display: flex;
      > .image {
        width: 100%;
      }
    }
  }
  &__content {
    flex: 1;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    &--with-bg-color {
      justify-content: center;
    }
    .box-content {
      width: 100%;
      max-width: 600px;
      box-sizing: border-box;
    }
    .subtitle {
      font-weight: $bold;
      font-size: $regularSize;
      margin-bottom: 10px;
      margin-top: 10px;
      display: inline-block;
    }
    .text {
      font-weight: $regularSize;
      font-size: $regular;
    }
    .link {
      margin-top: 10px;
    }
    .image {
      align-self: flex-end;
      margin-right: 80px;
      img {
        width: 160px;
        height: 160px;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .imagequote {
    flex-direction: row;
    &__image {
      &--reduce {
        padding: 84px;
      }
      &--full {
        height: 700px;
      }
    }
    & > .image {
      flex: 1;
    }
    &__content {
      padding: 0;
      .box-content {
        padding: 0 $boxSizeMargin * 5;
      }
    }
    .subtitle {
      margin-bottom: 10px;
      margin-top: 15px;
    }
    .link {
      margin-top: 40px;
    }
  }
  .wysiwyg .imagequote {
    h2,
    h3 {
      font-size: $giantSize;
    }
  }
}
