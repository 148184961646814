.tab-view {
  position: relative;

  &__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 20px;
    overflow: auto;
    &__item {
      cursor: pointer;
      transition: all 0.6s;
      border-bottom: 1px solid transparent;
      opacity: .5;
      &--active {
        opacity: 1;
        border-bottom: 1px solid #000;
      }
      .h5 {
        font-size: $smallSize;
      }
    }
  }
  &--mobile .button {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $white;
    padding: 0.5rem 1.6rem;
    border-radius: 0;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lnr {
      font-weight: 800;
    }
  }
  &__list-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7.2rem;
    &__item {
      height: 0;
      opacity: 0;
      transition: all .6s;
      > * {
        display: none!important;
      }
      &--active {
        height: auto;
        opacity: 1;
        > * {
          display: block!important;
        }
      }
    }
  }

  &__content {
    padding-bottom: $boxSizeMargin * 5.6;
  }
  @include media-breakpoint-up(lg) {
    @include container-maxwidth();

    padding: 0;
    .button--link {
      height: $boxSizeMargin * 4;
      text-align: center;
      display: flex;
      padding: 0;
      position: relative;
      width: 100%;
      justify-content: center;

      .h5 {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        font-size: $smallSize;
      }
    }


    &__content {
      padding-top: 0;
    }
  }
}
