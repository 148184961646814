.fullscreen {
  > .link {
    padding-left: 0;
    padding-right: 0;
  }
  .spinner {
    margin: 100px;
  }
  &.skeleton-loading {
    .image {
      min-height: 300px;
      width: 100%;
    }
    margin-bottom: 20px;
  }
}
