.coupon-code {
  width: 100%;
  box-sizing: border-box;

  &__title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: $bold;
    border: none;
    width: 100%;
    padding: 0;
    font-family: $fontFamily;
    font-size: $smallSize;

    &:focus {
      border: none;
      outline: none;
    }

    .lnr {
      font-weight: $bold;
    }
  }

  &__form-with-code {
    padding: $boxSizeMargin;
    background-color: $pink;
    display: flex;
    justify-content: space-between;
    .coupon-code__code {
      font-weight: $bold;
      font-size: $tinySize;
      .price {
        margin-left: 0 !important;
        font-weight: $bold;
      }
    }
  }

  .button--link {
    font-weight: $regular;
    font-size: $extraSmallSize;
  }

  &__input {
    display: flex;
    flex: 1;
    justify-content: center;

    .icon svg {
      width: 14px;
      height: 14px;
    }
  }

  form {
    margin-top: $boxSizeMargin * 1.5;
    display: flex;
    align-items: center;

    .button {
      height: 32px;
      min-height: 0;
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    input {
      height: 32px;
      padding-right: $boxSizeMargin * 1.5;
      width: calc(100% + 50px);
    }
  }

  &__price {
    min-width: 60px;
    text-align: right;
    font-weight: $bold;
    margin-left: auto;

    .price {
      margin-left: 0 !important;
    }
  }
}
