.radio {
  display: flex;
  align-items: center;
  position: relative;

  > label {
    flex: 1;
    text-align: left;
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;

    & + label {
      position: relative;
      padding-left: $boxSizeMargin * 3;
      display: flex;
      align-items: center;
    }

    & + label:before,
    & + label:after {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      left: 0;
      top: calc(50% - 0.875rem);
    }

    & + label:before {
      border: 1px solid $borderGrey;
      border-radius: 50%;
    }

    & + label:after {
      transform: scale(0);
    }

    &:checked + label:before {
      border: 1px solid $borderGrey;
      border-radius: 50%;
    }

    &:checked + label:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: $brandPrimary;
      font-size: 20px;
      top: calc(50% - 5px);
      left: 4px;
      border-radius: 50%;
      transform: scale(1);
    }
  }
}
