.steps-parent {
  background-color: $white;
}

.steps-wrapper {
  padding: $boxSizeMargin 0;
}

.stepped-progress {
  text-align: center;
  position: relative;
  height: 60px;

  &__title {
    font-size: $extraSmallSize;
    font-weight: $bold;
    position: absolute;
    top: $boxSizeMargin * 3.8;
    left: 50%;
    transform: translateX(-50%);
  }

  &__line {
    position: absolute;
    display: block;
    height: 1px;
    background-color: $borderGrey;
    top: 16px;
    width: 100vw;
  }

  &__item {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 33px;
    width: 33px;
    border-radius: 50%;
    font-weight: $bold;
    line-height: 33px;
    background: $white;
    border: 1px solid $borderGrey;

    &--passed,
    &--selected {
      background-color: $black;
      color: $white;
    }
  }
}

@include media-breakpoint-up(lg) {
  .steps-wrapper {
    @include container-maxwidth();
    max-width: 1220px;
    background-color: $white;
    width: calc(100% - #{$boxSizeMargin} * 10);
  }

  .stepped-progress {
    &__line {
      left: 0;
      width: 100%;
    }
  }
}
