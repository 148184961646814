.product-item {
  position: relative;
  margin: $boxSizeMargin;
  max-width: $productMaxWidth;
  height: 325px;
  text-align: center;
  background: $white;
  border: 1px solid $borderGrey;
  padding: $boxSizeMargin * 2;
  transition: border-color 0.2s ease-in-out;
  overflow: hidden;
  box-sizing: border-box;

  > :first-child:not(.wysiwyg, .push) {
    height: 210;
  }
  > :first-child.wysiwyg,
  > :first-child.push {
    height: 100%;
  }

  &--grid {
    width: calc(50% - #{$boxSizeMargin * 1.6});
    margin: $boxSizeMargin * 0.8;
    padding: $boxSizeMargin;
    height: auto;

    .link {
      flex-direction: column;
      display: flex;
      position: relative;
    }

    .image {
      z-index: 1;
      height: 100%;
      min-height: 160px;

      img {
        max-height: 100%;
      }
    }

    .product-overview {
      position: relative;
      width: 100%;
    }
  }
  &--push {
    .image {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .product-list__item {
    max-width: none;
    width: 336px;
    height: 384px;
  }

  .product-item {
    > :first-child:not(.wysiwyg, .push) {
      height: 247px;
    }
    > :first-child.wysiwyg,
    > :first-child.push {
      height: 100%;
    }
    &--grid {
      padding: $boxSizeMargin * 2;
      height: 384px;
    }
  }
}
