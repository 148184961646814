.giftcard-synthesis {
  margin-bottom: 10px;
  form {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .form-item {
      width: 100%;
    }
    .form-item--inline  {
      width: calc(50% - 5px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
      .form-item__input {
        width: 100%;
      }
    }
  }
  .giftcard-synthesis__amount-choose {     
      border: none;
      padding: 0;
      font: inherit;
      display: flex;
      gap: 10px;
      display: contents;
      span {
          padding: 15px;
          border: 1px solid $shade04;
          border-radius: 5px;
          color: $black; 
          transition: all .3s;
          &:hover {
            cursor: pointer;
            border-color: $blue;
            color:$blue;
          }
          &.active {
            color:$blue;
            border-color: $blue;
        }
      }
  }
}