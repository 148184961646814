.image-text {
  @include container-maxwidth(0);
  margin-bottom: $boxSizeMargin * 0.8;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;

  &__video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__text-container {
    display: flex;

    color: $blue;
    font-family: $secondaryFontFamily;
    font-size: $extraLargeSize;
    font-weight: 600;

    .image-text__text {
      align-self: center;
      text-align: center;
      padding: $boxSizeMargin * 6 0;
    }
  }

  &__image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .link {
      display: inline-block;
    }
    .image-text__image {
      position: relative;
      width: 100%;
      height: 22.4rem;
      margin-bottom: $boxSizeMargin * 0.8;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .image--full::after,
    .image--full-rounded::after {
      padding-top: 50%;
    }

    flex-direction: row;
    margin-bottom: $boxSizeMargin * 2.4;

    &:not(.left) {
      .image-text__text-container {
        margin-right: 2rem;
        width: calc(50% - 2rem);
      }
    }
    &.left {
      flex-direction: row-reverse;
      .image-text__text-container {
        margin-left: 2rem;
        width: calc(50% - 2rem);
      }
    }

    &__image-container {
      display: flex;
      flex-direction: column;
      min-width: 38rem;

      .image-text__image {
        flex: 1;
      }
    }
    &__text-container {
      font-size: 4rem;
    }
    .image-text__text {
      align-self: center;
      text-align: left;
      padding: $boxSizeMargin * 6;
    }

    // &__image-container {
    //   .image-text__image {
    //     height: 100%;
    //     margin-bottom: 0px;
    //   }
    // }
  }
}
