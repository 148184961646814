.product__delivery-infos {
  margin-bottom: $boxSizeMargin * 2;
  &__wrapper {
    background-color: $grey;
    padding: $boxSizeMargin * 2.4;
    line-height: 1.8rem;
  }

  &__title {
    margin-bottom: $boxSizeMargin * 1.6;
    font-weight: $bold;

    span {
      color: $brandPrimary;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    font-size: $smallSize;

    .icon {
      margin-right: $boxSizeMargin;
      margin-top: $boxSizeMargin * 0.5;
    }

    &:first-child {
      margin-bottom: $boxSizeMargin;
    }
  }
}

@include media-breakpoint-up(lg) {
  .product__delivery-infos {
    margin-bottom: 0;
  }
}
