.searchbar-results {
  .stack {
    .only {
      width: 50em;
    }
  
  } 
  padding: $boxSizeMargin;
  width: 100vw;
  display: flex;

  &--empty {
    text-align: center;
  }

  &__results {
    display: inline-flex;
    margin: 0;
  }
  &__element {
    flex: 1;
    padding: $boxSizeMargin;
    min-width: initial;
  }

  @media screen and (min-width: 25rem) {
    &__element {
      min-width: initial;
    }
  }
  @media screen and (min-width: 40rem) {
    padding: $boxSizeMargin * 1.5;
    width: auto;
    min-width: initial;
    &__element {
      min-width: initial;
      flex: 1;
    }
  }

  .close-search {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
  }
}

.results-block {
  &__title {
    .h3 {
      font-size: 15px;
      margin-left: 1em;
    }
  }
}

.autocomplete {
  width: 100%;
  top:50%;
  &__category {
    margin-left: 2em;
  }
}

.searchbar-product {
  .autocomplete-results {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    grid-auto-rows: auto; 
    margin : 30px;
    &__option {
      width: 12em;
    }
  }
  
  .autocomplete-item {
    display: list-item;
    &__image {
      width: 8em;
      height: 8em;
      margin-inline: auto;
    }
    &__name {
      margin-inline: auto;
      font-size: x-small;
      text-align: center;
    }
  }
}

.searchbar-categories { 
  .autocomplete-results {
    position: relative;
    display: block;
    padding: 0;
    margin: math.div(-$boxSizeMargin, 2);
    list-style: none;
    text-align: left;
  
  
    &__option {
      padding: math.div($boxSizeMargin, 2);
      position: relative;
      display: block;
  
      &--selected {
        background: $shade02;
      }
    }
  }
  .autocomplete-item {
    &__name {
      font-size: small;
    }
  }
}

.search-box {
  position: static;
}


@media screen and (max-width: 1050px) {
  .autocomplete {
    top: 95%;
  } 
  .searchbar-results {
    .stack {
      display: flex;
      flex-direction: column;
      width: inherit; 
    } 
  &__results {
    display: flex;
    flex-direction: column-reverse;
    overflow-x: hidden;
  }
}
  .close-search {
    display: none;
  }

  .search-box {
    position: static;
  }

  .searchbar-product {
    margin-top: 3em;
    .results-block {
      padding-bottom: 20em;
      margin-bottom: 20em;
    }
    .autocomplete-results {
      display: grid;
      grid-template-columns: repeat(2, 1fr); 
      grid-auto-rows: auto; 
      margin-left: 0;
      &__option {
        width: 12em;
      }
    }
  }
}