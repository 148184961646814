.stickers {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  flex-wrap: wrap;
  &__item {
    padding: 5px 10px;
    border: 1px solid transparent;
    color: $black;
    background: $pink;
    margin-right: 10px;
    font-weight: $bold;
    font-size: $extraSmallSize;
    margin-bottom: 10px;
    &--new {
      background-color: $orange;
      color: $white;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(menuBreakpoint) {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
.product-item .stickers {
  z-index: 2;
  max-height: 30px;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    max-height: auto;
    overflow: visible;
  }
}
