.contact {
  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    max-width: 89rem;
    padding: 0 $boxSizeMargin $boxSizeMargin $boxSizeMargin;
    margin: 0 auto;
  }
  &__main-title {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    left: 0;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 5% !important;
    color: $white;
    @include container-maxwidth();
  }
  &__success {
    text-align: center;
    strong {
      margin: $boxSizeMargin * 2 0;
      display: inline-block;
    }
  }
  &__header {
    text-align: left;
    margin: 0 auto $boxSizeMargin * 2 * 2 auto;
  }
  &__image-header {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: auto;
    max-height: 300px;
    .image {
      position: relative;
    }
    .responsive-image__actual {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    flex: 1;
    margin: $boxSizeMargin 0 $boxSizeMargin * 4 0;
    padding: $boxSizeMargin $boxSizeMargin * 2 $boxSizeMargin * 2 $boxSizeMargin *
      2;

    background: $white;

    .form-item {
      margin: 1.6rem 0;
    }

    textarea {
      min-height: 16rem;
    }

    .button {
      padding: 1rem 5rem;
    }
  }

  &__aside {
    margin-left: 0;
    margin-top: $boxSizeMargin * 2;

    .test {
      width: 220px;
      height: 170px;
      background: white;
      margin-bottom: 1rem;
    }
  }
  input[type="file"] + p {
    font-size: $tinySize;
  }
}

@include media-breakpoint-up(lg) {
  .contact {
    &__wrapper {
      flex-direction: row;
      padding: 0 0 $boxSizeMargin 0;
    }
    &__content {
      margin: $boxSizeMargin * 4 0;
      padding: $boxSizeMargin * 4.8 $boxSizeMargin * 12;
    }

    &__aside {
      margin-left: $boxSizeMargin * 2.4;
      margin-top: $boxSizeMargin * 4;
    }
    &__image-header {
      height: 300px;
      .responsive-image__actual {
        max-width: 100%;
        width: 1900px;
        height: 550px;
        object-fit: cover;
      }
    }
  }
}
