.four-entries {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__entry {
    width: 50%;
    height: 218px;
    background-color: $grey !important;
    position: relative;
    display: flex;
    overflow: hidden;
    &--with-shadow {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: -49px;
        box-shadow: 0 -72px 35px 35px rgb(0 0 0 / 25%);
        z-index: 1;
      }
    }
    &__title {
      z-index: 2;
      position: absolute !important;
      min-width: 50%;
      min-height: 24px;
      left: 0;
      right: 0;
      bottom: 16px;
      margin: auto;
      text-align: center;
      color: $white;
      font-size: $regularSize;
      font-family: $secondaryFontFamily;
      font-weight: $semiBold;
    }
    &__image {
      height: 100%;
    }
    .link {
      display: block;
      width: 100%;
    }
    .image {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.skeleton-loading .text--animate {
    width: 50%;
  }
  @include media-breakpoint-up(menuBreakpoint) {
    flex-wrap: nowrap;
    &__entry {
      height: 513px;
      &__title {
        bottom: 32px;
        font-size: $extraLargeSize;
      }
    }
  }
}
