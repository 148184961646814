.configurator {
  width: 100%;
  min-width: 10em;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;

  .alert {
    position: absolute;
    top: 14px;
    right: 40px;
    padding: 0;
    margin: 0;
    background: none;
    font-size: $extraSmallSize;
    font-family: $fontFamily;
    font-weight: $bold;
  }
  &__select > * {
    margin-bottom: $boxSizeMargin;
  }
  &__select {
    margin-bottom: $boxSizeMargin;
    .select__placeholder {
      font-size: $smallSize;
    }
    .select__menu {
      border-radius: 0;
      box-shadow: none;
      border-width: 1px;
      margin-top: 0;
      left: -1px;
      &-list {
        padding: 0;
      }
      .option-label {
        &--out-stock {
          text-decoration: line-through;
        }
      }
      .out-of-stock {
        font-size: $extraSmallSize;
        font-weight: normal;
      }
    }
    .select__option--is-disabled {
      text-decoration: none;
    }
    .select__option {
      border-bottom: 1px solid $borderGrey;
      display: flex;
      gap: 8px;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        color: $black;
        background-color: $borderGreyLight !important;
      }
    }
  }
  .diff-price {
    color: $blue;
    margin-left: 10px;
  }

  .select--light .select__input > :last-child {
    font-size: $smallSize;
  }

  .form-item {
    flex-direction: row;
    align-items: center;
    display: block;
    .react-select {
      border: 1px solid $borderGrey;
      padding: 6px 12px;
    }
    &__input {
      width: 100%;
      .input-wrapper {
        margin-top: 0;
        font-weight: $bold;
      }
      .select__control {
        cursor: pointer;
      }
    }
    &__label {
      display: none;
    }
  }

  &__custom-options {
    .form-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__input {
        > * {
          margin-top: 0;
        }

        .radio {
          margin: 0;
        }
      }
    }
  }
}

.product-swatches {
  &__label {
    font-weight: $semiBold;
    margin-bottom: $boxSizeMargin * 0.5;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;

    &__swatch {
      position: relative;
      margin-right: 1rem;
      margin-bottom: 1.2rem;
      width: 40px;
      height: 40px;
      background: $white;
      border: 1px solid $shade04;
      border-radius: 50%;
      padding: 3px;
      box-sizing: border-box;
      &__image,
      &__color {
        display: block;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        &.disabled {
          opacity: 0.4;
        }
      }
      &__image {
        position: relative;
      }

      &:hover,
      &--selected {
        border: 2px solid $brandPrimary;
      }
      &:focus {
        outline: none;
        border: 3px solid $shade04;
      }
      &--disabled {
        border: 1px solid $shade04;
        background-color: rgba($shade04, 0.3);
        cursor: not-allowed;

        .product-swatches__values__swatch > * {
          opacity: 0.4;
        }
      }
    }
  }
}
