.account-orders {
    .dropdown {
      margin: $boxSizeMargin 0;
  
      .order-recap {
        border-left: 2px solid $shade04;
        padding: $boxSizeMargin * 0.25 0 $boxSizeMargin * 0.25 $boxSizeMargin;
        margin-top: $boxSizeMargin * 0.5;
        margin-left: $boxSizeMargin * 0.5;
      }
    }
    .recap-card {
      margin-bottom: $boxSizeMargin * 2;
      &__title {
        display: inline-block;
        font-family: $secondaryFontFamily;
        font-weight: $semiBold;
        font-size: $regularSize;
      }
      &__description {
        display: inline-block;
        background: $pink;
        border-radius: 100%;
        padding: 3px 12px;
        margin-left: $boxSizeMargin * 2;
        font-family: $secondaryFontFamily;
        font-weight: $semiBold;
        font-size: $regularSize;
      }
    }
  }
  