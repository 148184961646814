.account-form--password-request {
  p {
    text-align: center;
    margin-bottom: $boxSizeMargin * 2;
  }

  hr {
    width: 100%;
    margin: $boxSizeMargin * 2 0;
  }

  .form-actions {
    margin-top: $boxSizeMargin * 2;
    justify-content: center;
    flex-direction: column;

    .link {
      color: $black;
      background: $white;
      text-align: center;
      width: auto;
      padding: $boxSizeMargin * 0.95 $boxSizeMargin * 2.4;

      &:hover {
        background: $black;
        color: $white;
      }
    }
  }

  .alert__content {
    flex-direction: column;

    &__message {
      text-align: center;
      font-size: $smallSize;
    }

    svg {
      margin-bottom: $boxSizeMargin;
    }
  }
}

@include media-breakpoint-up(lg) {
  .account-form--password-request {
    .account-form--register {
      &__wrapper {
        min-width: 58rem;
      }
      &__connection {
        padding: $boxSizeMargin * 4.8 $boxSizeMargin * 6.2 $boxSizeMargin * 2
          $boxSizeMargin * 6.2;
      }
    }
  }
}
