.user-information {
  &__content {
    display: flex;
    flex-direction: column;

    &__item + &__item {
      margin-top: $boxSizeMargin;
    }
  }

  .account-newsletter__button {
    display: flex;
    justify-content: center;
  }

  .form-actions {
    .button {
      margin-top: $boxSizeMargin * 1.5;
    }
  }
}

@include media-breakpoint-up(lg) {
  .user-information {
    &__content {
      &__item {
        display: flex;
        flex: 1;
        background: $white;

        margin-bottom: $boxSizeMargin * 1.6;

        &:last-child {
          margin-bottom: $boxSizeMargin * 3.2;
        }

        > *:first-child {
          border-right: 1px solid $borderGrey;
        }
      }
      &__item + &__item {
        margin-top: 0;
      }

      .box {
        flex: 1;
        padding: $boxSizeMargin * 3.2 $boxSizeMargin * 6 $boxSizeMargin * 3.2
          $boxSizeMargin * 5.6;
      }

      .form-actions {
        justify-content: flex-end;
      }
    }

    .account-newsletter__button {
      justify-content: flex-start;
    }
  }
}
