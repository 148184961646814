.labelled-icon {
  display: inline-block;
  text-align: center;
  margin: 0;

  &__content {
    display: flex;
    width: auto;
    align-items: center;

    > :first-child {
      display: block;
      margin-right: $boxSizeMargin * 1.4;
    }
  }

  &__icon {
    display: flex;
    position: relative;

    &__notification {
      font-size: $smallSize;
      position: absolute;
      top: -0.5em;
      right: -1em;
      color: $notificationColor;
      line-height: 1em;
      padding: 0.2em;
      border-radius: 0.2em;
      background: $notificationBackground;
    }
  }

  &--vertical {
    .labelled-icon {
      &__content {
        flex-direction: column;

        > * + * {
          margin-top: 0.2rem;
        }

        > :first-child {
          margin-right: 0;
        }
      }
    }
  }
}
