.empty-cart {
  text-align: center;
  @include container-maxwidth();
  background-color: $white;
  padding: 0;
  .icon--cart {
    display: inline-block;
    background-color: $pink;
    padding: 60px;
    border-radius: 100%;
    position: relative;
    margin-top: $boxSizeMargin * 10;
    margin-bottom: $boxSizeMargin;

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      right: -35px;
      width: 40px;
      height: 40px;
      background: $pink;
      border-radius: 100%;
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &__goback {
    padding: $boxSizeMargin * 3 0 $boxSizeMargin * 10 0;

    .button {
      font-size: $smallSize;
      min-height: auto;
      svg {
        width: 10px;
        height: 10px;
      }
    }
    .lnr {
      margin-right: $boxSizeMargin;
    }
  }
}
