.widget-lookbook {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  .swipe {
    width: 100%;
    height: 100%;
    &__current {
      height: 100%;
    }
  }
  .spinner__loading .icon {
    height: 100%;
  }
  .image--cover img {
    object-fit: cover;
  }
  &.loading-skeleton {
    .widget-theme__right {
      min-height: 500px;

      &__item {
        min-height: 250px;
        background-color: $grey;
      }
    }
    .widget-theme__left {
      background-color: $grey;
      min-height: 500px;
    }
  }
  .button {
    padding: 0;
  }
  .product-list__item {
    max-width: 100%;
    margin: 0;
    padding: 0;
    height: 290px;
  }

  &__left {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;

    .button {
      position: absolute;
      z-index: 1;
      &:nth-child(1) {
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(270deg);
      }
    }

    img {
      height: 812px;
      width: 100%;
    }
  }
  &__right {
    width: 100%;
    &.product-item--grid {
      width: 100%;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    &__item {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      &--media {
        .image   {
          height: 395px;
        }
        img {
          height: 395px;
          width: 100%;
          object-fit: contain !important;
        }
      }
      &--product {
        border: 1px solid $borderGrey;
        .product-item {
          border: none;
          margin: 0 auto;
        }
      }
      &:nth-child(1) {
        width: 100%;
        align-items: flex-start;
        margin: 0;
        padding: 20px 0;
      }
      &:nth-child(2) {
        width: 0;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: $boxSizeMargin * 2;
      }
    }
  }

  @media screen and (min-width: $xlWidth) {
    flex-direction: row;
    .image--cover img {
      object-fit: contain;
    }

    .product-list__item {
      height: 330px;
    }
    &__left {
      width: 50%;
    }
    &__right {
      flex-direction: row;

      width: calc(50% - #{$boxSizeMargin * 2});
      &.left {
        margin-left: $boxSizeMargin * 2;
        margin-top: 0;
      }
      &.right {
        margin-right: $boxSizeMargin * 2;
        margin-bottom: 0;
      }
      &__item {
        width: calc(50% - #{$boxSizeMargin});
        height: 395px;
        min-height: 250px;
      }
    }
    &__right__item--media img {
      object-fit: cover !important;
    }
  }
  @media screen and (max-width: $mdWidth) {
    .widget-theme__right__item,
    .widget-theme__right__item--media img,
    .widget-theme__right__item--product .product-item,
    .widget-theme__right__item--media .image {
      height: 248px;
    }
    .widget-theme__left img {
      height: auto;
    }
  }
}
