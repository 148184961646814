.news-list {
  background-color: $white;
  &__list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__title {
    font-family: $secondaryFontFamily;
    font-size: $extraLargeSize;
    font-weight: $semiBold;
    text-align: center;
    margin-bottom: 37px;
    display: block;
    width: 100%;
  }
  &--loading-skeleton {
    .news-thumbnail {
      position: relative;
      background-color: $grey;
      .news-thumbnail__title,
      .news-thumbnail__date,
      .news-thumbnail__short-description,
      .link {
        background-color: $borderGrey;
        overflow: hidden;
      }
      .news-thumbnail__date {
        width: 30%;
      }
      .link {
        width: 30%;
      }
      .news-thumbnail__title::after,
      .news-thumbnail__date::after,
      .news-thumbnail__short-description::after,
      .link::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $borderGrey,
          lighten($borderGrey, 4%),
          $borderGrey
        );
        animation: progress 1s ease-in-out infinite;
      }
      .news-thumbnail__image {
        background-color: $borderGrey;
      }
    }
  }
}
.news-thumbnail {
  width: 342px;
  max-width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 20px;
  .responsive-image--loading {
    min-height: 342px;
    width: 100%;
    background-color: $borderGrey;
    .responsive-image__placeholder {
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    margin: 0 24px;
    font-family: $fontFamily;
    font-size: $smallSize;
  }
  &__image {
    width: 100%;
    height: 342px;
    position: relative;
  }
  &__title {
    width: 100%;
    position: relative;
    margin-top: 8px;
    font-family: $secondaryFontFamily;
    font-size: $regularSize;
    font-weight: $semiBold;
  }
  &__date {
    min-height: 18px;
    width: 100%;
    position: relative;
    margin-top: 23px;
    font-family: $fontFamily;
    font-size: $extraSmallSize;
  }
  &__short-description {
    width: 100%;
    position: relative;
    margin-top: 8px;
    overflow: hidden;
  }
  .link {
    margin-top: 17px;
    position: relative;
    min-height: 21px;
    padding: 0;
    width: 100%;
    color: $black;
    display: flex;
    align-items: center;
    .lnr {
      margin-left: 10px;
      color: $black;
    }
  }
}
