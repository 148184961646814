@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin mobile-maxwidth {
  max-width: $maxWidthMobile;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: #{$maxWidthMobile}) {
    padding-right: $boxSizeMargin * 1.6;
    padding-left: $boxSizeMargin * 1.6;
  }
}

@mixin container-maxwidth($boxSizeMarginMobile: $boxSizeMargin) {
  max-width: $containerMaxWidth;
  margin: 0 auto;
  padding-right: $boxSizeMargin * 3;
  padding-left: $boxSizeMargin * 3;
  box-sizing: border-box;

  @media screen and (max-width: #{$maxWidthMobile}) {
    padding-right: $boxSizeMarginMobile * 1.6;
    padding-left: $boxSizeMarginMobile * 1.6;
  }
}
