.page-error {
  max-width: 30rem;
  margin: $boxSizeMargin * 2 auto;
}
.not-found {
  @include mobile-maxwidth;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
