// Cleanup by extracing a `FilterItem` component that should be used in `Filter.js` and `LayeredNavigation.js`
.filter {
  border-top: none;
  border-bottom: 1px solid $borderGrey;

  &:last-child {
    border: none;
  }

  &__title {
    padding: $boxSizeMargin 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .h3 {
      font-size: $smallSize;
      font-weight: $regular;
      font-family: $fontFamily;
    }
    > * {
      margin: 0;
    }
  }
  &--opened {
    .filter__content {
      padding-left: $boxSizeMargin;
      padding-bottom: $boxSizeMargin;
      font-size: $smallSize;
    }
  }

  .button--ghost {
    color: $black;
    height: auto;
    padding-left: 0;
    .labelled-icon__content .body {
      margin-right: $boxSizeMargin;
    }
  }
}

@include media-breakpoint-up(lg) {
  .filter {
    margin: 0 $boxSizeMargin * 0.5;
    padding: $boxSizeMargin 0;

    &:first-child {
      padding-top: 0;
    }

    &--title:first-child {
      padding-top: 0;
    }
  }
}
