.topbar {
  background: $white;
  padding-bottom: 0;

  .delivery {
    text-align: center;
    background: $grey;
    color: $darkGrey;
    line-height: 2.4rem;
    border-bottom: 1px solid $borderGreyLight;
  }

  .container {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .lnr {
        font-size: 2rem;
      }
    }
    &__right {
      justify-content: flex-end;

      .lnr {
        font-size: $smallIconSize;
      }
    }

    &__menu-links {
      margin-right: $boxSizeMargin * 2.4;
    }

    &__logo {
      display: flex;
      align-items: center;
      margin-left: $boxSizeMargin * 1.4;
      margin-bottom: -5px;
      img {
        height: 2.6rem;
        width: auto;
      }
    }

    &__links {
      border-right: 1px solid $darkGrey;
      line-height: $boxSizeMargin * 2.2;
      small {
        font-family: $secondaryFontFamily;
        font-size: $menuSmallSize;
        font-weight: $semiBold;
        a {
          padding: 0 $boxSizeMargin;
        }
      }
    }
  }
  &__mobile_search {
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
  }

  .mini-cart,
  .icon--user {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .icon--search {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .mini-cart {
    display: flex;
    .button--link {
      padding: 0;
      margin: 0;
    }
  }
}

@include media-breakpoint-up(menuBreakpoint) {
  .topbar {
    background-color: $grey;
    padding: 0;
    line-height: 1.8rem;

    .retailer-link {
      font-size: $smallSize;
    }
    .container {
      @include container-maxwidth();
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 2rem;
      border-bottom: 1px solid $grey;
      background-color: $white;
    }

    .store-view-selector,
    .labelled-icon {
      font-size: $regularSize;
    }
    &__wrapper {
      .labelled-icon {
        &__label {
          font-size: $smallSize;
        }
      }
      &__search {
        flex: 2;
        text-align: right;
      }
      &__logo {
        flex: 1.5;
        margin-bottom: 0;
        img {
          height: 4.7rem;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 135px;
        }
      }
      &__menu-links {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .store-view-selector {
      .select__input > select {
        font-family: $secondaryFontFamily;
        font-size: $menuSmallSize;
      }
    }
  }

  .mini-cart,
  .icon--user {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
