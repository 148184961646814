.product-price {
  font-weight: $bold;

  &--special {
    .product-price__price {
      > * {
        vertical-align: bottom;
      }
    }
    .product-price__prefix {
      margin-right: $boxSizeMargin * 0.25;
    }
    .product-price__regular {
      text-decoration: line-through;
    }
    .product-price__special {
      font-weight: $regular;
      font-size: $mediumSize;
      margin-left: $boxSizeMargin * 0.25;
    }
  }

  &--product-page {
    .price {
      font-family: $secondaryFontFamily;
      font-size: $regularSize;
      font-weight: $semiBold;
    }

    &.product-price--special {
      .product-price__regular {
        color: $darkGrey;
        font-weight: $semiBold;
        font-size: $regularSize;
        margin-left: $boxSizeMargin;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .product-price {
    &--product-page {
      .price {
        font-size: $extraLargeSize;
      }

      &.product-price--special {
        .product-price__regular {
          font-size: $extraLargeSize;
        }
      }
    }
  }
}
