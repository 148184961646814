.other-lookbook {
  width: 100%;
  &--skeleton {
    margin-top: 20px;
    .other-lookbook__title {
      width: 250px;
      height: 30px;
      position: relative;
      overflow: hidden;
      background-color: $grey;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    
    }
    .other-lookbook__item {
      position: relative;
      overflow: hidden;
      background-color: $grey;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
  }
  &__title {
    display: block;
    font-size: $regularSize;
    font-family: $secondaryFontFamily;
    font-weight: $semiBold;
    margin-bottom: 10px;
  }
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item {
    width: 288px;
    height: 200px;
    margin-bottom: 20px;
    .button, .image, img {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    &__title {
      font-size: $extraLargeSize;
    }
    &__list {
      justify-content: flex-start;
    }
    &__item {
      width: 220px;
      height: 320px;
      margin-bottom: 20px;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    
    }
  }
}