.account-orders-details {
  .recap-table {
    .recap-table__line {
      text-align: left;
    }
  }
  &__totals {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__total {
    max-width: 342px;
    width: 100%;
    margin: 0 auto;
    .button {
      margin: $boxSizeMargin auto;
    }
    .total__recap {
      .recap-table__line {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        &--total-excl-tax, &--tax {
          display: none;
        }
      }
      &__detail-shipping {
        width: 100%;
        .recap-table__group__title {
          margin: 0;
        }
        .recap-table__line {
          padding: 0;
          margin: 0;
          p {
            font-size: $extraSmallSize;
          }
        }
        strong.body {
          font-weight: normal;
        }
        &__title {
          display: block;
          margin: 5px 0;
          font-size: $extraSmallSize;
        }
        &__address-label {
          display: block;
          margin-top: 20px;
        }
        &__address {
          display: block;
          margin: 5px 0;
        }
      }
    }
  }
  &__item {
    border: none;
    border-bottom: 1px solid $borderGrey;
    padding: $boxSizeMargin 0;
    margin-bottom: 10px;
    display: flex;

    .sku {
      display: block;
      margin-bottom: 10px;
    }
    &:last-child {
      border-bottom: none;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      align-items: center;
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      max-width: 250px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__title {
      font-weight: $semiBold;
    }
    &__image {
      display: inline-block;
      position: relative;
      width: 128px;
      height: 112px;
      margin: 5px;
    }
  }

  &__ordered-items {
    > *:first-child {
      padding-top: 0;
    }
    > .account-orders-details__item {
      max-width: 703px;
      width: 100%;
    }
  }

  &__top-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $extraSmallSize;
  }

  &__delivery {
    margin: $boxSizeMargin 0;
    border-top: 1px solid $divider;
    &:last-child {
      border-bottom: 0;
    }
  }

  .dropdown {
    .quoted {
      border-left: 2px solid $shade04;
      padding: $boxSizeMargin * 0.25 0 $boxSizeMargin * 0.25 $boxSizeMargin;
      margin-top: $boxSizeMargin * 0.5;
      margin-left: $boxSizeMargin * 0.5;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .quoted > * {
    margin-top: $boxSizeMargin * 0.5;
    margin-bottom: 0;
  }

  &__total {
    padding: $boxSizeMargin 0 $boxSizeMargin * 0.5;
    margin-bottom: $boxSizeMargin;
    text-align: center;

    .total {
      table {
        display: inline-block;
      }

      &__price {
        padding: 0;
      }
    }
  }

  &__comments {
    border-collapse: collapse;
    width: 100%;

    th {
      font-weight: $bold;
      padding: $boxSizeMargin $boxSizeMargin * 0.5 $boxSizeMargin 0;
      border-bottom: 1px solid $shade03;
      text-align: left;
    }

    td {
      padding: $boxSizeMargin 0;
      border-bottom: 1px solid $shade03;
      width: 100%;
    }

    tr {
      &:first-child {
        th,
        td {
          padding-top: 0;
        }
      }
      &:last-child {
        th,
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  .recap-card__children {
    padding-bottom: 0;
  }
}

@media screen and (min-width: $menuBreakpoint) {
  .account-orders-details {
    &__totals {
      flex-direction: row;
    }
    &__total {
      width: 342px;
      padding: 0;
    }
    &__item {
      border: 1px solid $borderGrey;
      &:last-child {
        border-bottom: 1px solid $borderGrey;
      }
      &__content {
        flex-direction: row;
        padding: 0 40px 0 20px;
      }
      &__wrapper {
        flex-direction: column;
        margin-bottom: 0;
      }

      &__image {
        display: inline-block;
        position: relative;
        width: 128px;
        height: 112px;
        margin: 20px;
      }
    }

    &__ordered-items {
      > *:first-child {
        padding-top: 0;
      }
      > .account-orders-details__item {
        width: 703px;
      }
    }

    &__delivery {
      margin-bottom: 0;
      border-bottom: 1px solid $divider;
      .account-orders-details__item {
        border: none;
      }
      &__addresses,
      &__methods {
        display: flex;
        justify-content: space-between;
        > * {
          flex: 1;
        }
      }
      &__methods {
        > * {
          padding-bottom: 0;
        }
      }
    }
    &__comments {
      th {
        padding-right: $boxSizeMargin;
      }
    }
  }
}
