a,
.link {
  color: $black;
  text-decoration: none;

  &:hover {
    color: $brandPrimary;
  }
}

.link--small {
  font-size: $extraSmallSize;
  color: $black;
}

.link--block {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
