.body {
  &--fade {
    color: $darkGrey;
    font-style: italic;
  }
}

p {
  font-size: $smallSize;
  font-weight: $regular;
  line-height: 1.5;
}

p.small,
p small {
  font-size: $smallSize;
  font-weight: $regular;
  line-height: 1.5;
}

small {
  font-size: $extraSmallSize;
}

@include media-breakpoint-up(lg) {
  p {
    font-size: $regularSize;
  }

  p.small,
  p small {
    font-size: $smallSize;
  }

  small {
    font-size: $extraSmallSize;
  }
}
