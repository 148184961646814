.account-form {
  position: relative;
  @include mobile-maxwidth;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  &__wrapper {
    z-index: 1;
    margin: $boxSizeMargin * 2 $boxSizeMargin * 1.6;
    border: 1px solid $borderGrey;
    background: $white;
  }

  &__title {
    height: $boxSizeMargin * 6.5;
    .h5 {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      line-height: $boxSizeMargin * 6.5;
      text-align: center;
    }

    &--register {
      background: $pink;
    }

    &--login {
      background: $grey;
    }
  }

  &__content {
    padding: $boxSizeMargin * 2;

    p {
      margin-block-start: 0;
      margin-block-end: $boxSizeMargin * 2;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    .button {
      display: flex;
      height: auto;
      justify-content: center;
      width: 100%;
      max-width: $boxSizeMargin * 25;
      margin: 0 auto $boxSizeMargin * 2 auto;
      padding: $boxSizeMargin * 2.4 0;

      .labelled-icon--vertical .labelled-icon__content > * + * {
        margin-top: 1.2rem;
      }
    }
  }

  &__sign-up:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  &__login-link {
    .link {
      width: 100%;
      text-align: center;
      margin-top: $boxSizeMargin * 2;
    }
  }

  .newsletter-checkbox {
    .form-item {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex-flow: row-reverse;
      margin-bottom: 1em;
      &__label {
        margin-left: 1em;
      }
    } 
    .label--optional {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .account-form {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    &__wrapper {
      max-width: 100%;
      width: 59rem;
      box-sizing: border-box;
      margin: $boxSizeMargin * 2 auto;
      padding: $boxSizeMargin * 4.8 $boxSizeMargin * 6.2;
    }

    &__content {
      padding: 2rem 0;

      .fieldset {
        width: 100%;
        padding: 0;
        padding-block-start: 0px;
        padding-block-end: 0px;
        padding-inline-start: 0px;
        padding-inline-end: 0;
        margin: 0 auto;
      }

      .form-actions--full .button {
        width: auto;
      }
    }

    hr {
      margin: 2.4rem 0;
    }

    &__links {
      flex-direction: row;
      justify-content: space-between;
      .button {
        width: 22rem;
      }
    }

    &__login-link {
      text-align: center;
      .link {
        width: auto;
        text-align: center;
      }
    }
  }
}

.ReactModalPortal .reassurance--big {
  display: none;
}
