.number-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 (-$boxSizeMargin * 0.5);

  .button--disabled {
    color: $blackDisabled;
  }

  &__button {
    margin: 0 $boxSizeMargin * 0.5;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > * {
      display: block;
    }

    .button {
      height: 33px;
      width: 33px;
      padding: 0;
      min-height: 33px;
    }
  }

  &__input {
    text-align: center;

    > * {
      width: 3rem;
      padding: 0;
    }

    > input {
      width: 3rem;
      padding: 0;
      text-align: center;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $borderGrey;
      font-size: $smallSize;
      font-weight: $bold;
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0;
    width: 100%;
    .number-input__button {
      &:first-child {
        margin-top: $boxSizeMargin * 0.25;
      }
      &:last-child {
        margin-bottom: $boxSizeMargin * 0.25;
      }
    }
    .number-input__input {
      > * {
        height: 2em;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
