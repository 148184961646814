$navigationMenuHeight: 7rem;
$subNavigationMenuHeight: 7rem;

.navigation {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 1.6rem 0 8rem 0;
  }
  &__menu {
    flex: 2;
  }
  &--sticky {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
  }
  &--loading {
    .navigation-menu__item{
      color: $darkGrey;
      cursor: not-allowed;
    }
  }
}

.navigation-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: $white;
  &__item-list {
    display: flex;
    flex-direction: column;
    flex: 6;
    &__gender-menu {
      display: flex;
      .navigation-menu__item {
        text-transform: uppercase;
        font-family: $secondaryFontFamily;
        font-size: $smallSize;
        font-weight: $bold;
        &--active {
          position: relative;
          &:hover {
            border-bottom: 2px solid white!important;
          }
          &:before {
            content: "";
            position: absolute;
            width: 65%;
            height: 2px;
            background: black;
            top: 80%;
            left: 0;
            right: 0;
            margin: auto;
          }

        }
      }
    }
    &__menu {
      display: flex;
      margin: 0;
      align-self: flex-start;
      padding-left: 230px;
      > .navigation-menu__item--active, > .navigation-menu__item:focus, > .navigation-menu__item:hover {
        border: none;
        border-bottom: 3px solid $black;
        &:after {
          display: none;
        }
      }
    }
  }
  &__item {
    font-size: $menuSize;
    width: 100%;
    font-weight: $semiBold;
    cursor: pointer;
    color: $black;
    box-sizing: border-box;
    text-decoration: none;
    display: block;
    text-align: center;

    &--disable-click {
      cursor: default;
    }

    &--active,
    &:focus {
      text-decoration: none;
      color: $brandPrimary;
      border: 1px solid $borderGreyDarkness;
      border-top: none;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: white;
      }
    }
    &--loading {
      cursor: not-allowed;
      color: $darkGrey !important;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        z-index: 2;
        color: black;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%0A%3Csvg version='1.1' id='L9' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve'%3E%3Cpath fill='%23000' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'%3E%3CanimateTransform attributeName='transform' attributeType='XML' type='rotate' dur='1s' from='0 50 50' to='360 50 50' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &:hover {
      color: $black;
    }

    &--mobile {
      width: 100%;
      display: flex;
      align-items: left;

      > :first-child {
        text-align: left;
      }
      > :last-child {
        margin: 0;
      }

      .labelled-icon {
        width: 100%;
        &__content {
          display: flex;
          justify-content: space-between;
        }

        .lnr--small {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
      .labelled-icon__content .body {
        text-align: left;
      }
    }
  }
  &__submenu {
    @extend .navigation-menu;
    left: 0;
    top: 100%;
    position: absolute;
    z-index: 10;
    max-width: $containerMaxWidth;
    .wysiwyg {
      background-color: $white!important;
    }
    .navigation-menu__item {
      color: $white;
      background: $brandPrimary;
    }
  }
}

.listed-link {
  &__item {
    margin: 0.3em 0;
  }
}

@include media-breakpoint-up(menuBreakpoint) {
  .example-appear {
    opacity: 0.01;
  }

  .example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
  .navigation {
    background-color: $grey;
    min-height: 89px;
    .container {
      @include container-maxwidth();
      padding: 0;
      background-color: $white;
    }
    &__menu {
      flex: 1;
    }
    &__wrapper {
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .navigation-menu {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 0;
    border-bottom: 1px solid $borderGreyDarkness;
    border-top: 1px solid $borderGreyDarkness;

    & > .navigation-menu__item {
      border-bottom: 2px solid $white;
      &:hover {
        border-bottom: 2px solid $black;
      }
    }
    &__submenu {
      position: absolute;
      top: calc(#{$navigationMenuHeight} + #{$subNavigationMenuHeight});
      left: 0px;
      right: 0;
      max-width: 100%;
      overflow: hidden;
      max-height: 0;
      transition: all 0.5s ease-in-out;
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      padding: 0;
      border: none;
      &__wrapper {
        @include container-maxwidth();
        box-shadow: $menuBoxShadow;
        margin: 0 auto;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        background-color: $white;
        border-top: 1px solid $borderGrey;
        border-bottom: 1px solid $borderGrey;
        &__images {
          width: 50%;
          .wysiwyg {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            & > div {
              flex: 1;
            }
          }
        }
        &__item {
          width: 50%;
          display: flex;
          justify-content: center;
        }
      }

      .navigation-menu__item {
        background: transparent;
        font-size: $menuSize;
        font-weight: $semiBold;
        height: auto;
        padding: 0.8rem 0;
        text-align: left;
        &--active,
        &:focus,
        &:hover {
          color: $black !important;
          text-decoration: underline;
        }
      }

      &__sub-category--grid1,
      &__sub-category--grid2,
      &__sub-category--grid3 {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: $boxSizeMargin;
        padding-bottom: $boxSizeMargin * 2.0;
        padding-left: 20px;
        .navigation-menu__item {
          color: $black;
          font-weight: $regular;
          font-size: $smallSize;
          font-family: $fontFamily;
          padding: 0;
          margin: 10px 0 0 0;
        }
        .navigation-menu__item-section-title {
          font-weight: $bold;
          font-size: $regularSize;
          margin-top: 1rem;
          text-align: left;
        }
      }
    }
     
    .navigation-menu__item-list {
      display: flex;
      background: $white;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: center;
      padding: 0;
      &:hover {
        .navigation-menu__item {
          color: $darkGrey;
        }
      }
      .navigation-menu__item {
        font-family: $secondaryFontFamily;
        font-weight: $extraBold;
        font-size: $smallSize;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid $black;
        }
        &--promote {
          > span {
            background-color: $pink;
            padding: 10px 20px;
            border-radius: 20px;
            height: auto;
          }
        }
      }
    }
    .logo {
      height: 4.7rem;
      width: auto;
      margin-right: $boxSizeMargin * 5;
      padding-left: $boxSizeMargin * 5;
    }

    .mini-cart {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__icons {
      display: flex;
      flex: 1;
      align-items: center;
      z-index: 1;

      .button,
      .link {
        width: 9.8rem;
        font-weight: $semiBold;
        font-family: $secondaryFontFamily;
      }

      .lnr--small {
        font-size: 1.7rem;
      }

      .body {
        font-size: $tinySize;
        margin-top: 0.5rem;
      }
    }

    &__item {
      display: flex;
      color: $black;
      height: $navigationMenuHeight;
      font-size: $smallSize;
      margin: 0;
      padding: 0 $boxSizeMargin * 2;
      border-bottom: 0px;
      width: auto;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
      opacity: .75;
      border-bottom: 3px solid transparent;
      span {
        display: flex;
        position: relative;
        height: 100%;
        align-items: center;

        &:after {
          content: "";
        }
      }

      &--active,
      &:hover,
      &:focus {
        color: $black !important;
        opacity: 1;
      }

      &--push {
        span {
          height: auto;
          background: $brandSecondary;
          padding: $boxSizeMargin * 0.6 $boxSizeMargin * 1.5;
          border-radius: $boxSizeMargin * 1.4;
        }
      }

      &--active {
        &.navigation-menu__item--push {
          position: relative;
          span {
            padding: $boxSizeMargin * 0.6 $boxSizeMargin * 1.5;
            border-radius: 1.4rem;
          }
          span:after {
            content: none;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            background: $black;
            width: 6.3rem;
            height: 1px;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
