.add-to-cart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;


  &__error {
    width: 100%;

    > * {
      margin-top: 0;
    }
  }

  &__missing-option {
    margin-top: 2rem;
    text-align: center;
    color: $orange;
    width: 100%;
    font-weight: $bold;
  }

  &__button {
    width: 100%;
    height: 5rem;
    flex: 1 0 60%;
    box-sizing: border-box;
    padding: 0;
    margin-top: 1rem;

    > * {
      width: 100%;
      height: 100%;
      max-width: 45rem;
    }
  }

  &__qty {
    box-sizing: border-box;
    flex: 1 0 40%;
    padding: $boxSizeMargin * 0.5;
    font-size: 1.2em;

    .form-item {
      &--inline {
        align-items: center;
      }
      &__label {
        margin-right: 2.4rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .add-to-cart {
    &__missing-option {
      text-align: left;
      font-weight: $regular;
    }

    &__button {
      height: 4rem;

      > * {
        width: 100%;
        height: 48px;
      }
    }
  }
}
