.gift-card{
    margin-top: 20px;
    background-color: white;
    .spinner {
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    &__title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $bold;
        border: none;
        width: 100%;
        padding: 0;
        font-family: $fontFamily;
        font-size: $smallSize;
    
        &:focus {
          border: none;
          outline: none;
        }
    
        .lnr {
          font-weight: $bold;
        }
      }

    .gift-card__button {
        border: none;
      }
    
    .gift-card-form {
        margin-top: 1.5rem;
    }

    .gift-card-sold-button {
        button {
            text-decoration: underline;
            border: none;
            color: grey;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            &:hover, &:focus {
                background: none;
            }
    }
  }
    
    .gift-card-form-main {
        display: flex;
        #code {
            width: calc(100% + 50px);
            border: black solid 1px;
            height: 32px;
        }
        button {
            height: 32px;
            min-height: 32px;
            padding-top: 7px;
        }
    }

    .gift-card-form-result {
        display: flex;
        place-content: space-between;
        background-color: $pink;
        #code {
            margin-left: 10px;
            align-self: center;
            font-size: small;
            font-weight: bold;
        }
        button {
            border: none;
            font-size: small;
            background: $pink;
        }
    }  
}
  