.parallax-images {
  display: flex;
  position: relative;
  overflow: hidden;
  &--link {
    display: block;
    padding: 0;
  }
  .image {
    width: 183px;
    height: 250px;
    position: absolute;
    img {
      height: 100%;
    }
    &:nth-child(1) {
      left: -80px;
      top: 24px;
      z-index: 1;
    }
    &:nth-child(2) {
      width: 217.4px;
      height: 298px;
      position: relative;
      margin: 0 auto;
    }
    &:nth-child(3) {
      right: -80px;
      top: 24px;
      z-index: 1;
    }
  }
  @media (min-width: "500px") {
    .image {
      &:nth-child(1) {
        left: -40px;
        top: 24px;
      }

      &:nth-child(3) {
        right: -40px;
        top: 24px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .parallax-outer {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      &:last-child {
        right: 0;
        left: initial;
      }
    }
    .image {
      height: 335px;
      width: 264px;
      position: initial;
      &:nth-child(1) {
        left: 0;
        top: 0;
        z-index: 1;
      }
      &:nth-child(2) {
        height: 502px;
        width: 286px;
        margin: 0 auto;
        position: initial;
      }
      &:nth-child(3) {
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
  }
  @include media-breakpoint-up(menuBreakpoint) {
    .image {
      height: 635px;
      width: 464px;
      &:nth-child(2) {
        height: 802px;
        width: 586px;
      }
    }
  }
}
