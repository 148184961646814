.content-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 40px;
  &--skeleton {
    .button--primary {
      background-color: $grey;
    }
    .content-slider__left {
      background-color: $grey;
      height: 400px;
      max-height: 30vh;
      margin-bottom: 20px;
    }
    .content-slider__right {
      height: 400px;
      max-height: 40vh;
    }
    .content-slider__title {
      position: relative;
      height: 30px;
      width: 50%;
      background-color: $grey;
      display: block;
    }
    .content-slider__subtitle {
      position: relative;
      height: 20px;
      width: 40%;
      margin-top: 20px;
      background-color: $grey;
      display: block;
    }
    .content-slider__text {
      height: 80px;
      width: 80%;
      display: block;
      background-color: $grey;
    }
    .content-slider__content {
      width: 100%;
    }
    .skeleton--animate {
      position: relative;
      overflow: hidden;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
    height: 400px;
    max-height: 80vh;
  }
  .link {
    display: block;
    height: 100%;
  }
  .image--loading {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 50px 0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .button--primary {
    position: absolute;
    top: auto;
    bottom: 0;
    margin: auto 0;
    height: 4rem;
    width: 4rem;
    padding: 0;
    z-index: 2;
    svg {
      height: 10px;
      width: 10px;
    }
    &:last-child {
      right: 0;
    }
    &:first-child {
      left: 0;
    }
  }
  &__full {
    display: flex;
    flex-direction: row;
    transition: transform 0.3s ease;
  }
  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  &__left {
    width: 100%;
    position: relative;
  }
  &__right {
    width: 100%;
  }
  &__title {
    font-size: $extraLargeSize;
    margin-top: 0;
  }
  &__subtitle {
    font-size: $regularSize;
  }
  &__text  {
    font-size: $smallSize;
  }
  .button--ghost {
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
    .button--primary {
      position: absolute;
      top: 0;
      bottom: 0;
    }
    .image--loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &--skeleton {
      .content-slider__left {
        max-height: 80vh;
        margin-bottom: 0;
      }
      .content-slider__right {
        max-height: 80vh;
      }
    }
    &__left {
      width: 50%;
    }
    &__right {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: $boxSizeMargin * 2;
    }
    &__item {
      flex-direction: row;
      padding: 0 100px;
    }
    &__title {
      font-size: $giantSize;
    }
    &__subtitle {
      font-size: $extraLargeSize;
    }
    &__text  {
      font-size: $regularSize;
    }
  }
}
