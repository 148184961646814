.footer {
  background-color: $grey;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $boxSizeMargin * 2.4;
  font-size: $menuSize;
  color: $darkGrey;

  .footer__wrapper {
    display: flex;
    flex-direction: column;
    max-width: $containerMaxWidth;
    width: 100%;
    margin: auto;
  }

  .link-list {
    &__list {
      text-indent: 0;
      margin-block-start: $boxSizeMargin * 0.8;
      margin-block-end: 0;
    }
    &__title {
      font-size: $menuSize;
      font-weight: $bold;
      margin-bottom: 0;
    }

    &__item {
      margin-bottom: $boxSizeMargin * 0.8;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & + .link-list__item {
      margin-top: 0;
    }
    .link {
      color: $darkGrey;
    }
  }

  &-legislation {
    background-color: $grey;
    ul,
    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
    }

    .link-list {
      &__list {
        margin-left: $boxSizeMargin * 1.6;
      }
    }
  }

  .logo {
    height: auto;
    margin: auto;
    margin-top: $boxSizeMargin * 3.2;
  }

  &__copyright {
    width: 100%;
    text-align: center;
    margin-top: $boxSizeMargin * 1.6;
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    padding: 0 $boxSizeMargin * 3 $boxSizeMargin * 1.2 $boxSizeMargin * 3;
    .footer__wrapper {
      flex-direction: row;
      padding-top: $boxSizeMargin * 4;
      max-height: 21rem;
    }

    .logo {
      height: 4.7rem;
      margin-top: 0;
    }

    .bottombar {
      width: 75%;
    }

    &__copyright {
      text-align: right;
      max-width: 1440px;
      margin: auto;
    }
  }
}
