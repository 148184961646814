.option-picker {
  &__option {
    background-color: $optionItemBackground;
    border: 1px solid $divider;
    border-radius: 5px;
    color: $optionItemColor;
    padding: 0.5em;
    text-align: center;
    box-sizing: border-box;
    font-size: $extraSmallSize;
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &--disabled {
      border-color: $shade03;
      border-width: 1px;
      color: $shade03;
    }

    &--selected,
    &--clickable:hover:not(&--disabled) {
      border: 1px solid $brandPrimary;
      cursor: pointer;
      color: $brandPrimary;
    }
    &--selected {
      font-weight: bold;
    }

    &--rounded {
      display: inline-block;
      padding: 0;
      height: 1.5em;
      width: 1.5em;
      border-radius: 50%;
      box-sizing: content-box;
    }
    &--normal {
      display: inline-block;
    }
  }
  > .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    font-size: $smallSize;
    font-weight: $regular;
    > .lnr {
      margin-right: 10px;
    }
  }
}

.checkbox-wrapper {
  label {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin: 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
