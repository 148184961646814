.store-view-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 100px;
  background-color: $white;
  z-index: 2;
  .similar-color__modal__list {
    display: flex;
    justify-content: space-around;
    margin: 0;
    align-items: center;
    height: 100%;
  }
}
.store-view-overlay {
  content: '';
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $black;
  opacity: .4;
  display: inline-block;
  position: absolute;
}
.store-view-mobile {
  display: flex;
  gap: 10px;
  text-transform: uppercase;
}
.store-view-selector {
  border: none;
  padding: 0;
  display: block;
  font-weight: $bold;
  font-size: $smallSize;
  text-transform: uppercase;
  z-index: 100;
  
  .react-select {
    .select__control {
      color: $blue;
      cursor: pointer;
      padding: 0 5px 0 10px;

      .select__single-value {
        color: $blue;
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 47px;
        @include media-breakpoint-up(menuBreakpoint) {
          width: 38px;
          width: 100%;
          padding-right: 6px;
          font-size: $smallSize;
        }
      }
    }
    .select__option {
      display: flex;
      text-transform: uppercase;
      padding-left: 8px;
      gap: 11px;
      font-size: $smallSize;
      &:hover {
        background-color: $pink;
      }
    }
    .select__menu {
      top: 36px;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 4;
    }
    .lnr-chevron-down:before {
      font-weight: 800;
      font-size: $tinySize;
      display: inline-block;
      position: relative;
      top: -3px;
    }
  }

  &__item > * {
    text-transform: uppercase;
  }

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
  &--active {
    font-weight: bold;
    text-decoration: underline;
    cursor: default;
  }

  .select__indicators {
    display: none;
  }

  .react-select.select--light .select__value-container {
    width: 53px;
    text-transform: uppercase;
  }
}
@include media-breakpoint-up(lg) {
  .store-view-selector {
    font-size: $regularSize;
    font-weight: $regular;
    text-transform: none;

    .react-select .select__control {
      color: $black;

      .select__option {
        color: $black;
      }
      .select__single-value,
      .select__option {
        color: $black;
      }
      &--menu-is-open {
        .select__single-value,
        .select__option {
          color: $blue;
        }
      }
    }
    &:hover .react-select .select__control {
      .select__single-value,
      .select__option {
        color: $blue;
      }
    }

    & .select__input > :last-child {
      padding: 0 $boxSizeMargin * 1.2 0 $boxSizeMargin * 0.5;
    }

    .select__indicators {
      display: flex;
    }
  }
}
