$boxSizeMargin: 1rem;

@import "./normalize";

body {
  margin: 0;
}

.ReactModal__Overlay--after-open {
  z-index: 40;
}

.ReactModal__Body--open {
  overflow: hidden;
}

button {
  background-color: inherit;
}

@import "~theme/components/components";
@import "~theme/layouts/Layout";
@import "~theme/modules/modules";
@import "~theme/pages/pages";
@import "./override";
.bubble-info {
  &__text {
    display: none;
  }
  &:hover {
    position: relative;
    .bubble-info__text {
      display: block;
      position: absolute;
      top: 48px;
      left: 0;
      z-index: 1;
      background: $black;
      color: $white;
      padding: 10px;
      font-size: 12px;
      width: 200px;
      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 10px;
        border-right: 12px solid transparent;
        border-bottom: 15px solid $black;
        border-left: 13px solid transparent;
      }
      @media only screen and (max-width: 1200px) {
        width: auto;
      }
    }
  }
}
.mb-30-div {
  & > div, & > section {
    margin-bottom: 30px;
  }
}
.mb-20-div {
  & > div, & > section {
    margin-bottom: 20px;
  }
}