.toggle-button {
  .icon {
    transition: transform 0.3s ease;
  }

  &--expanded {
    .icon {
      transform: rotate(180deg);
    }
  }
}
