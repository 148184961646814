@use "sass:math";

.search-box {
  input {
    border:none;
    border-bottom: 4px solid #f9f9f9;
  }
  position: relative;
  &__form {
    position: relative;
    .one-line-form{
      &__input {
        display: flex;
      }
    }
  }
}
.input-wrapper--searchbar + .search-close {
  position: absolute;
  top: 0;
  right: 35px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.search--opened {
  overflow: hidden;
  height: 100vh;
}
body .searchbar-results,
body .searchbar-results__element {
  box-sizing: border-box;
  width: 100%;
  place-content: normal;
  margin-top: 10px;
  overflow-y: auto;
    max-height: 60em;
}

@media screen and (max-width: 1050px) {
  .search-mobile{
    display: flex;
  }
  .search-input-hidden {
    display: none;
  }
  .stack {
    max-width: -webkit-fill-available;
  }
}

@import "~./SearchBarResults/SearchBarResults";
@import "~./CategoriesResults/CategoriesResults";
