.cookie-notice {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $white;
  border-top: 1px solid $shade05;
  color: $black;
  width: 100%;
  text-align: center;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 30;
  font-size: $extraSmallSize;
  padding-bottom: 10px;

  .labelled-icon__content {
    display: flex;
  }
  &__modal {
    position: relative;
    overflow: auto;
    border-radius: 5px;
    outline: none;
    margin: auto;
    max-width: 90%;
    background: $white;
    padding: 0;
    box-sizing: border-box;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .cookie-notice {
    font-size: $smallSize;
    &__modal {
      width: 70%;
      max-width: 80em;
    }
  }
}

.cookie-page {
  padding-top: 20px;
}
@media screen and (min-width: #{$menuBreakpoint}) {
  .cookie-page {
    padding-top: 50px;
  }
}
