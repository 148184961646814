.confirmation-card {
  text-align: center;
  color: $success;

  .icon {
    justify-content: center;
  }

  &__indicator {
    display: inline-block;
    padding: 20px;
    background: $pink;
    width: 100%;
    max-width: 342px;
    margin: $boxSizeMargin * 2 auto;
    .icon {
      display: none;
    }
  }
  &__title {
    > * {
      margin: 0 0 $boxSizeMargin * 0.5 0;
    }
  }
  &__confirm {
    margin: $boxSizeMargin * 2 auto;
  }
}

@include media-breakpoint-up(lg) {
  .confirmation-card {
    margin-top: $boxSizeMargin;
    margin-bottom: boxSizeMargin;

    &__indicator {
      margin: 0 auto $boxSizeMargin auto;
    }
    p {
      margin-top: 0;
    }

    .recap-card__title {
      display: none;
    }
    .box {
      padding: 0;
    }
    &__confirm {
      margin: 0 auto $boxSizeMargin * 6 auto;
    }
  }
}
