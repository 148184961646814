fieldset {
  border: none;
  font-size: $regularSize;
  max-width: 20em;
  margin: 0 auto;
  padding: 0;

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  + * {
    margin-top: $boxSizeMargin;
  }
}
.fieldset--large {
  max-width: 47rem;
}
