.socialnewsletter-bar {
  display: flex;
  flex-direction: column;
  background-color: $white;
  max-width: $containerMaxWidth;
  width: 100%;
  margin: 2px auto 0 auto;
  padding: $boxSizeMargin * 1.6;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 40px;

  &__description {
    flex: 2;
    text-align: center;
    font-size: $smallSize;
    font-weight: $regular;
    strong {
      font-size: $mediumSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
      display: block;
      margin-bottom: 10px;
      color: $black;
    }
  }
  .subscribe {
    flex: 3;
    width: auto;
    padding: 0 20px;
    position: relative;
    margin: 30px auto 60px auto;
    max-width: 100%;
    box-sizing: border-box;
    form {
      display: block;
      margin-left: 10px;
    }
    label {
      display: block;
      color: $black;
      margin-bottom: 3px;
    }
    &__input {
      width: auto;
      display: flex;
    }
    .alert {
      box-sizing: border-box;
      position: absolute;
      bottom: -70px;
      background: transparent;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .input-wrapper {
      width: 230px;
    }
    .button {
      margin-left: -20px;
    }
  }
  &__networks {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    padding: 3.2rem 10rem;
    margin-bottom: 0;

    .subscribe {
      margin: 0 auto;
      form {
        margin-left: 50px;
      }
      .input-wrapper {
        width: 342px;
      }
    }
    &__networks {
      justify-content: space-between;
    }
    &__description {
      text-align: left;
    }
  }
}
