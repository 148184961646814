.account {
  margin: 0 auto;
  &__content {
    @include container-maxwidth();
    width: 100%;

    &__divider {
      padding: $boxSizeMargin $boxSizeMargin * 2;
      background: $white;
    }
  }

  &__title {
    background-color: $white;
    padding: $boxSizeMargin * 2 0;
    margin-bottom: $boxSizeMargin * 2;

    &__wrapper {
      @include container-maxwidth();

      > :first-child {
        margin: 0 auto;
        > * {
          display: block;

          &:first-child {
            font-weight: $semiBold;
            color: $black;
          }
        }
      }

      .button--link {
        height: auto;
        margin-bottom: $boxSizeMargin * 2;
        small {
          color: $darkGrey;
        }
      }
    }
  }

  .box {
    padding: $boxSizeMargin * 2;
    max-width: 100%;
  }

  .dashboard__address {
    margin-bottom: $boxSizeMargin * 3.1;
    &__form {
      padding: $boxSizeMargin * 2 0;
      border-bottom: 1px solid $borderGrey;

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
      }

      &__index {
        font-weight: $bold;
      }
    }

    .select--invalid,
    .select--valid {
      .select__input {
        border-color: $borderGrey;
        > :last-child {
          color: $black;
        }
      }
    }

    .checkbox {
      padding-top: $boxSizeMargin * 2;

      &:last-child {
        padding-bottom: $boxSizeMargin * 2;
      }
    }
    .warning-street-length {
      background-color: $grey;
      padding: 5px;
      font-size: $smallSize;
      box-sizing: border-box;
    }
  }

  .form-actions--only-one,
  .form-actions--center {
    justify-content: flex-start;
  }

  .form-actions--only-one > *,
  .form-actions--center > * {
    margin: 0;
  }
  .dashboard-laposte {
    background-color: $white;
    padding: 20px;
    &__no-order {
      margin: 0 auto;
    }
    .wysiwyg {
      background-color: $white;
    }
    #orderId {
      background-color: $buttonPrimaryColor;
      border-radius: 20px;
      .select__control {
        background-color: transparent;
      }
      .select__value-container {
        justify-content: center;
      }
      .select__placeholder,
      .select__single-value {
        color: $white;
        font-size: $smallSize;
        font-weight: $bold;
      }
      svg {
        fill: $white;
      }
    }
    &__row {
      display: flex;
      flex-direction: column;
      &__item {
        border-bottom: 1px solid $borderGrey;
        padding: 0 40px;
        flex: 1;
        &:last-child {
          border-right: 0;
        }
      }
      &__form {
        width: 100%;
        margin: 40px auto 0 auto;
        padding-bottom: 10px;
        border-bottom: 1px solid $borderGrey;
        button {
          margin: 10px auto 10px auto;
          display: block;
        }
      }
    }
    &__button button {
      width: 100%;
      margin: 40px auto 0 auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .account {
    @include container-maxwidth();
    display: flex;
    padding-left: 0;
    padding-right: 0;
    .dashboard-laposte {
      &__row {
        display: flex;
        flex-direction: row;
        &__item {
          border-right: 1px solid $borderGrey;
          border-bottom: none;
          padding: 0 40px;
          flex: 1;
          &:last-child {
            border-right: 0;
          }
        }
        &__form {
          width: 50%;
          margin: 40px auto 0 auto;
        }
      }
      &__button button {
        width: 50%;
        margin: 40px auto 0 auto;
      }
    }

    &__title {
      padding: 0;

      &__wrapper {
        margin: 0;
        padding-right: 0;
        padding-left: 0;
      }

      > :first-child {
        > * {
          &:first-child {
            margin-right: 0.25em;
          }
        }
      }
    }

    &__left {
      background: $white;
      padding: $boxSizeMargin * 4.2 $boxSizeMargin * 2.4 $boxSizeMargin * 4.2
        $boxSizeMargin * 3;
    }

    &__content {
      padding-right: 0;
      padding-left: $boxSizeMargin * 3.2;
      padding-top: $boxSizeMargin * 1.6;

      .account-main {
        width: 100%;
      }
    }

    .dashboard__address {
      .box {
        padding: 0;
      }

      .recap-card__title {
        align-items: center;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding-left: $boxSizeMargin * 5.6;
        padding-top: $boxSizeMargin * 2.4;

        > :first-child {
          padding-bottom: 0;
        }

        .form-actions--only-one,
        .account .form-actions--center {
          padding-left: $boxSizeMargin * 1.6;
        }
      }

      .recap-card__children {
        display: flex;
        flex-wrap: wrap;
      }

      &__form {
        padding: 0 $boxSizeMargin * 5.5 $boxSizeMargin * 3.1 $boxSizeMargin * 6;
        width: 100%;
        box-sizing: border-box;

        .form-actions {
          margin-top: $boxSizeMargin * 2.2;
          justify-content: flex-end;

          > *:first-child {
            margin-right: $boxSizeMargin * 1.6;
          }
        }

        &--create .grid {
          flex-direction: column;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .account {
    .dashboard__address {
      .recap-card__title {
        width: 50%;
        border-right: 1px solid $borderGrey;
      }

      &__form {
        width: 50%;
        border-right: 1px solid $borderGrey;

        &:nth-child(2n) {
          border-right: none;
        }
      }
    }
  }
}
