.push {
  &.loading-skeleton {
    min-height: 350px;
    min-width: 350px;
    position: relative;
    .push__image--image {
      height: 100%;
      width: 100%;
      display: inline-block;
      background-color: $grey;
    }
    .push__image__content {
      width: 75%;
      @media screen and (max-width: $lgWidth) {
        width: 100%;
      }
    }
    .push__image {
      flex: 1;
      height: 100%;
      background-color: $grey;
    }
    .link {
      width: 100%;
      border: 1px solid $borderGrey;
    }
    .push__image-container {
      display: flex;
      flex-direction: column;
    }
    .push__image__content--text {
      display: inline-block;
      width: 60%;
      height: 20px;
      margin: 10px 0;
      background-color: $borderGrey;
      border-radius: 5px;
      overflow: hidden;
      position: relative;

      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $borderGrey,
          lighten($borderGrey, 4%),
          $borderGrey
        );
        animation: progress 1s ease-in-out infinite;
      }
    }
  }
  display: flex;
  flex-direction: column;

  .responsive-image--loading {
    min-height: 300px;
    width: 100%;
  }
  &__image-container {
    flex: 1;

    .push__image {
      position: relative;
      width: 100%;
      &__hover {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-transition: opacity .2s ease-in-out;
        -moz-transition: opacity .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out;
        transition: opacity .2s ease-in-out;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        &--show {
          opacity: 1;
          -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=1);
        }
      }
      .spinner__loading .icon {
        width: 100%;
        height: 100%;
      }
      .image {
        height: 100%;
        width: 100%;
      }
      &__content {
        position: absolute;
        top: inherit;
        left: 0;
        bottom: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        color: $white;
        z-index: 2;
        padding: 0 $boxSizeMargin * 5.5;
      }

      h4 {
        font-size: $regularSize * 1.4;
        text-align: center;
      }

      .labelled-icon__icon {
        font-size: $tinySize;
      }
      .lnr--small {
        font-size: $tinySize;
        font-weight: $bold;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: initial;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
      }
    }
  }
  .image--gradient:before {
    @media screen and (max-width: $lgWidth) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, transparent);
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 350px;
    .image--full::after,
    .image--full-rounded::after {
      padding-top: 50%;
    }

    flex-direction: row;

    &__image-container {
      display: flex;
      flex-direction: column;

      .push__image {
        flex: 1;

        h4 {
          font-size: $extraLargeSize;
        }
        height: 100%;

        &__content {
          padding: 0 $boxSizeMargin * 3.2 $boxSizeMargin * 3.2 $boxSizeMargin *
            3.2;
          top: inherit;
          bottom: 0;
          width: auto;
          height: auto;
          align-items: flex-start;

          h4 {
            text-align: left;
            margin-top: $boxSizeMargin * 1.6;
            margin-bottom: $boxSizeMargin * 1.6;
            max-width: 34rem;
          }
        }
      }
    }
  }
  &--full {
    max-width: 100%;
    width: 100%;
    @include media-breakpoint-up(lg) {
      .push__image-container .push__image__content {
        top: 0;
        margin: auto;
        justify-content: center;
      }
    }
  }
}
// listing
.product-list__item.product-push {
  padding: 0;
  p {
    margin: 0;
  }
  .push__image-container {
    min-width: auto;
  }
  .push__image > .link,
  .push {
    height: 100%;
  }
  @media screen and (max-width: $lgWidth) {
    width: 100%;
    max-width: 100%;
  }
}
