.paginated {
  margin: 0 auto;
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__list {
      width: 100%;

      .grid-cards {
        padding: 0;
        margin: ($boxSizeMargin * 0.5) 0;
      }
    }
  }
}
.head-sort-limit {
  border: none;
  max-width: 1500px;
  margin: 0 auto;
  padding-right: 3rem;
  padding-left: 3rem;
  box-sizing: border-box;
  padding: 0 3rem;
  position: relative;
  right: 0;
  top: 0;
  margin-bottom: $boxSizeMargin * 2;
  margin-top: $boxSizeMargin * 2;
  display: flex;
  flex-direction: row;
  background-color: $white;
  flex-wrap: wrap;
  #limit {
    font-size: $extraSmallSize;
    width: 50%;
    padding-right: .8rem;
  }
  .select__control {
    cursor: pointer;
  }
  > form {
    width: 100%;
    margin-right: 0;
  }

  @media screen and (min-width: $containerMaxWidth) {
    flex-wrap: nowrap;
    margin-bottom: 0;
    margin-top: 0;
    top: 20px;
    position: absolute;
    align-items: center;
    top: -6rem;
    > form {
      margin-right: 40px;
      width: auto;
    }
    #limit {
      font-size: $extraSmallSize;
      width: 140px;
    }

  }
  @include media-breakpoint-up(sm) {
    form {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
@media screen and (min-width: $containerMaxWidth) {
  .paginated {
    background-color: $grey;
    &__body {
      @include container-maxwidth();
      flex-direction: row;
      padding-top: 8 * $boxSizeMargin;
      padding-bottom: 4 * $boxSizeMargin;
      background-color: $white;
      &__list {
        position: relative;
        .pagination {
          margin: $boxSizeMargin 0;
        }

        .grid-cards {
          @include container-maxwidth();

          padding: 0 $boxSizeMargin * 3;
        }
      }
    }
  }
}
