.delivery-estimation {
    width: 100%;
    box-sizing: border-box;
    &__title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $bold;
        border: none;
        width: 100%;
        padding: 0;
        font-family: $fontFamily;
        font-size: $smallSize;
        &:focus {
            border: none;
            outline: none;
          }
        .lnr {
            font-weight: $bold;
          }
    }
    &__validate {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .form-item {
        margin-top: 20px;
    }
    .item-with-addon {
        flex-wrap: nowrap;
        &__description {
            display: none;
        }
        &__name {
            font-weight: $regular;
        }
    }
    &__results {
      margin-top: $boxSizeMargin;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__item {
        border-bottom: solid 1px $divider;
        margin-top: $boxSizeMargin * 0.5;
        padding-bottom: $boxSizeMargin * 0.5;
  
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
  