.similar-color {
  width: 100%;
  border-top: 1px solid $borderGrey;
  padding: $boxSizeMargin 0;
  position: relative;
  > .form-item__label {
    display: flex;
    > * {
      width: auto;
    }
  }
  &__color-name {
    margin-left: 6px;
    font-weight: $bold;
    display: flex;
    align-items: center;
  }
  &__color-color {
    display: inline-block;
    box-sizing: border-box;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    margin-right: 10px;
  }
  &__color-count {
    color: $darkGrey;
    flex: 1;
    text-align: right;
    overflow: hidden;
    font-size: $extraSmallSize;
    &:not(&--disable-click) {
      cursor: pointer;
      text-align: center;
    }
    .icon {
      display: inline-block;
      margin-left: 5px;
      padding-right: 0.8rem;
    }
    &--open {
      .icon {
        transform: rotate(180deg);
        padding-left: 0.8rem;
        padding-right: 0;
      }
    }
  }
  &__thumbnails {
    display: flex;
    overflow: auto;
    width: 100%;
    flex-wrap: nowrap;
  }
  &__thumbnail {
    display: inline-block;
    box-sizing: border-box;
    width: 31px;
    height: 31px;
    padding: 3px;
    border-radius: 100%;
    border: 1px solid transparent;
    cursor: pointer;
    
    &:hover,
    &--current {
      border: 1px solid $black;
    }
    &__color {
      width: 100%;
      height: 100%;
      max-width: 31px;
      max-height: 31px;
      display: inline-block;
      border-radius: 100%;
      background-color: $borderGrey;
      
    }
  }
  &__modal {
    &__list {
      padding: 0;
      li {
        list-style: none;
      }
      .link {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: space-between;
        width: 100%;
        font-size: $mediumSize;
        font-weight: $bold;
        border-bottom: 1px solid $borderGrey;
        padding: $boxSizeMargin;
      }
    }
  }
}
// on product item grid
.product-item--withcolor {
  .similar-color {
    border-top: 0;
    display: none;
    padding: 0 10px;
    box-sizing: border-box;
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      .similar-color {
        display: flex;
        flex-direction: column;
      }
      .product-item__overview {
        .product-overview {
          position: relative;
        }
        width: 100%;
        display: block;
        position: absolute;
        bottom: 1px;
        z-index: 2;
        background-color: white;
        left: 0;
        .similar-color__color-count--disable-click {
          display: none;
        }
        .similar-color__color-count .icon {
          padding-right: 4px;
          transform: rotate(270deg);
        }
        .form-item__label {
          order: 2;
          display: flex;
          justify-content: center;
          > .similar-color__color-name {
            display: none;
          }
          > small {
            display: none;
          }
          > * {
            text-align: center;
          }
        }
        .similar-color__thumbnails {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
