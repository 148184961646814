$productMaxWidth: 288px;
@import "~theme/components/atoms/Breakpoints/breakpoints";
@import "~theme/components/helpers/mixins";
@import "~front-commerce/src/web/theme/components/components";
@import "~theme/components/helpers/skeletonLoading";
@import "~theme/components/helpers/ErrorBoundary/errorBoundary";
@import "~theme/components/atoms/Form/Input/Checkbox/Checkbox";
@import "~theme/components/atoms/ToggleButton/ToggleButton";
@import "~theme/components/molecules/Card/ContactCard/ContactCard";
@import "~theme/components/organisms/ProductSlider/ProductSlider";
@import "~theme/components/organisms/ContentSlider/ContentSlider";
@import "~theme/components/organisms/Reassurance/Reassurance";
@import "~theme/components/organisms/Swipe/Swipe";
@import "~theme/components/organisms/Widget/Instagram/Instagram";
@import "~theme/components/organisms/Hero/Hero";
@import "~theme/components/organisms/TabView/TabView";
@import "~theme/components/organisms/SizeGuide/SizeGuide";
@import "~theme/components/organisms/SimilarColor/SimilarColor";
@import "~theme/components/organisms/SizeAvailable/SizeAvailable";
@import "~theme/components/organisms/LookbookThumbnail/LookbookThumbnail";
@import "~theme/components/organisms/OtherLookbook/OtherLookbook";
@import "~theme/components/organisms/Stickers/Stickers";
@import "~theme/components/organisms/News/News";
@import "~theme/components/organisms/Widget/Push/Push";
@import "~theme/components/organisms/Widget/Lookbook/Lookbook";
@import "~theme/components/organisms/Widget/ImageQuote/ImageQuote";
@import "~theme/components/organisms/Widget/Fullscreen/Fullscreen";
@import "~theme/components/organisms/Widget/FourEntry/FourEntry";
@import "~theme/components/organisms/Widget/ImageText/ImageText";
@import "~theme/components/organisms/Widget/ProductPush/ProductPush";
@import "~theme/components/organisms/Widget/TitleText/TitleText";
@import "~theme/components/organisms/Widget/ParallaxImage/ParallaxImage";
@import "~theme/components/organisms/Widget/ThreePush/ThreePush";
@import "~theme/components/organisms/Widget/Ekomi/Ekomi";
@import "~theme/components/organisms/Widget/Theme/Theme";
@import "~theme/components/organisms/Widget/FourProduct/FourProduct";
@import "~theme/components/organisms/ProductReview/ProductReview";
@import "~theme/components/organisms/ModalAlertStock/ModalAlertStock";
@import "~theme/components/organisms/NewVisitorModal/NewVisitorModal";
@import "~theme/modules/ProductView/GiftCardSynthesis/GiftCardSynthesis";
@import "~theme/modules/Cart/CartGiftCard/CartGiftCard";
@import "~theme/pages/Account/Giftcards/Giftcards";
