.cart-item {
  &--loading {
    align-items: center;
  }
  &__product {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__remove {
    text-align: right;

    .icon svg {
      width: 16px;
      height: 16px;
    }

    .button--link {
      color: $fontColor;
      font-weight: $regular;
      font-size: $extraSmallSize;
      margin-bottom: $boxSizeMargin * 0.5;
    }
  }

  &__image {
    position: relative;
    flex: 0 0 30%;
    order: 0;
    display: flex;
    align-items: center;

    &__container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__details {
    flex: 0 0 65%;
    order: 1;
    margin-left: auto;
    padding-top: $boxSizeMargin * 0.5;

    &__options {
      font-size: $extraSmallSize;
      margin-top: $boxSizeMargin * 0.5;
    }
  }
  &__name {
    > :first-child {
      margin-top: 0;
      margin-bottom: -3px;
      .cart-item--loading & {
        margin-bottom: 0;
      }
    }

    .link {
      color: $fontColor;
      text-decoration: none;
    }

    .sku {
      color: $fontColor;
      font-size: $extraSmallSize;
    }
  }

  &__cart-infos {
    width: 100%;
    order: 2;
    padding-top: $boxSizeMargin;
  }
  &.cart-gift-message {
    background: $white;
    padding: $boxSizeMargin * 2;
    .cart-gift-message__headline {
      display: flex;
      font-size: $regularSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
      align-items: center;
      .icon {
        background-color: $pink;
        padding: 14px;
        border-radius: 100%;
        margin-right: 20px;
      }
      .checkbox {
        flex-direction: row-reverse;
        label {
          cursor: pointer;
        }
      }
    }
    .fieldset {
      margin: $boxSizeMargin * 2 0;
      input[type=text] {
        max-width: 343px;
      }
      textarea {
        max-width: 587px;
        width: 100%;
      }
      .form-item__label > * {
        font-size: $smallSize;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cart-item {
    flex-wrap: wrap;
    padding: $boxSizeMargin 0;

    &__image {
      order: 0;
      flex: 0 0 18%;
      max-width: 18%;

      &__container {
        margin-right: $boxSizeMargin;
        height: 115px;
      }
    }

    &__details {
      padding-top: $boxSizeMargin * 1.5;
      padding-left: 3%;
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;

      .cart-item--loading & {
        justify-content: center;
      }

      &__options {
        margin-top: $boxSizeMargin * 1.5;
      }
    }

    &__cart-infos {
      flex: 0 0 47%;
      padding: 0;
      margin: 0;
      border: 0;
      display: flex;
      align-items: center;
    }

    &__remove {
      order: 3;
      flex: 0 0 7%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.cart-gift-message {
      .fieldset {
        margin: $boxSizeMargin * 2 $boxSizeMargin * 6;
      }
    }
  }
}
