.modal-new-visitor {
    max-width: 400px!important;
    text-align: center;
    .button {
        width: 100%;
    }
    .product-added__wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        background-color: $pink;
        padding: 20px;
    }
    .product-added__title {
        font-weight: bold;
        padding: 0;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        margin-bottom: 10px;
    }
    .button--primary {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
    }
}