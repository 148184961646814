.push-product {
  width: 287.94px;
  max-width: 100%;
  height: auto;
  margin: auto;
  .stickers {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__item {
    height: 261px;
    position: relative;
    > .link,
    > .link .image,
    > .link .image img {
      height: 100%;
      display: inline-block;
      margin: 0 auto;
    }
    > .link {
      padding: 0;
      box-sizing: border-box;
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .h5 {
    font-weight: $semiBold;
    font-family: $secondaryFontFamily;
    font-size: $regularSize;
    margin: 5px auto;
    text-align: center;
  }
  &.loading-skeleton {
    height: 261px;
    img {
      height: 100%;
      width: 100%;
    }
    position: relative;
    overflow: hidden;
    background-color: $grey;

    &::after {
      @keyframes progress {
        0% {
          transform: translate3d(-100%, 0, 0);
        }
        100% {
          transform: translate3d(100%, 0, 0);
        }
      }
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        $borderGrey,
        lighten($borderGrey, 4%),
        $borderGrey
      );
      animation: progress 1s ease-in-out infinite;
    }
  }
  .product-item {
    position: absolute;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.5s;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
  }
  &--hover {
    .product-item {
      opacity: 1;
    }
  }
  @include media-breakpoint-up(menuBreakpoint) {
    width: 342px;
    &__item {
      height: 265px;
    }
    &.loading-skeleton {
      height: 265px;
    }
  }
  &--with-media-hover {
    .product-item {
        opacity: 1!important;
    }
  }
  &--hover-title {
      .product-item {
          opacity: 0!important;
      }
  }
  .product-item {
      &__add-to-cart {
          display: none;
      }
      &__overview {
          display: none;
      }
      
  }
  &__prices {
    margin-top: 5px;
    font-size: $smallSize;
    font-weight: normal;
    &--hidden {
      opacity: 0;
    }
    .product-price {
      display: inline-block;
    }
  }
}