@use "sass:math";

.mini-cart-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: math.div($boxSizeMargin, 2) 0;
  border-bottom: 1px solid $shade03;

  &__image {
    position: relative;
    flex: 0 0 15%;
  }

  &__quantity {
    width: 3em;
    text-align: center;
    margin: 0 math.div($boxSizeMargin, 2);
  }

  &__details {
    flex: 1;
    margin: 0 math.div($boxSizeMargin, 2);
  }
}
