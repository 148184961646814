.lookbook-thumbnails {
  margin-bottom: $boxSizeMargin * 3;
  margin-top: $boxSizeMargin;
  .button {
    padding: 0;
  }
  &__title {
    font-size: $regularSize;
    margin-bottom: $boxSizeMargin * 2;
  }
  &__items {
    white-space: nowrap;
    overflow: auto;
    .image {
      width: 159px;
      height: 178px;
    }
    .link {
      margin-right: $boxSizeMargin * 2;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $boxSizeMargin * 6;
    margin-top: $boxSizeMargin * 2;
  }
}
