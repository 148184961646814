.cart-content {
  padding: $boxSizeMargin * 1.6;
  background-color: $grey;

  &__payment-icon {
    max-width: 100%;
    text-align: center;
    margin-top: 20px;
    position: relative;
    .icon {
      position: absolute;
      right: 20px;
      top: 0;
    }
    strong {
      font-size: $smallSize;
    }
    p {
      font-size: $extraSmallSize;
      color: $darkGrey;
      margin-top: 0;
    }
  }
  &__list {
    > * + * {
      margin-top: $boxSizeMargin;
    }

    .box {
      @include mobile-maxwidth;
    }
    & > &__gift-item {
      margin-top: $boxSizeMargin * 3;
    }
    &__title {
      margin-bottom: 0;
      display: inline-block;
      font-size: $regularSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
    }
  }

  &__totals {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $grey;
    z-index: 2;
    touch-action: none;

    .total--visible .total__recap > .recap-table {
      max-height: calc(100vh - 240px);
      overflow-y: auto;
    }

    .box {
      max-width: none;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .form-actions .button--primary {
      border-radius: 0;
    }
    > .coupon-code, > .delivery-estimation, > .gift-card {
      padding: 0.8rem 1.6rem;
      box-sizing: border-box;
      background-color: $white;
      margin-top: 0;
    }
    .coupon-code {
      input {
        border-color: $black;
      }
    }
    > .wysiwyg {
      margin-top: $boxSizeMargin;
    }
  }

  .box + .box {
    margin-top: 3px;
  }
}
@include media-breakpoint-up(lg) {
  .cart-content {
    @include container-maxwidth();

    padding: $boxSizeMargin * 3;
    display: flex;

    &__list {
      flex: 1;
      margin-right: 3 * $boxSizeMargin;

      .box {
        max-width: none;
      }
      & > &__gift-item {
        margin-top: $boxSizeMargin * 3;
      }
      &__title {
        font-size: $extraLargeSize;
      }
    }

    &__totals {
      position: inherit;
      width: auto;
      flex: 0;

      .box {
        margin-bottom: 3 * $boxSizeMargin;
        padding: 0;
      }
      .form-actions .button--primary {
        border-radius: 24px;
        font-size:initial;
      }
      > .coupon-code, > .delivery-estimation, > .gift-card {
        margin-top: 20px;
      }
    }

    .box + .box {
      margin-top: 0;
    }
  }
}
