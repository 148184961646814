.expandable-card {
  background-color: $white;
  &__header {
    text-align: right;
    padding: $boxSizeMargin * 0.5 $boxSizeMargin * 1.6;
    cursor: pointer;
    color: $white;
    background-color: $black;
    box-sizing: border-box;
    font-size: $extraSmallSize;

    .toggle-button {
      margin-left: $boxSizeMargin;
    }
  }

  &__content {
    border-top: 1px solid $divider;
    padding: $boxSizeMargin $boxSizeMargin * 1.6;
  }

  &--expanded &__header {
    .icon {
      transform: rotate(180deg);
    }
  }

  &--inline {
    margin-bottom: $boxSizeMargin;
    .expandable-card__header {
      margin-top: 0;
      justify-content: flex-start;

      > :last-child {
        margin-left: $boxSizeMargin * 0.5;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .expandable-card {
    &__header {
      padding: $boxSizeMargin * 1.6;
      text-align: center;
      font-size: $regularSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
      position: relative;

      .toggle-button {
        position: absolute;
        right: $boxSizeMargin * 1.6;
      }
    }
  }
}
