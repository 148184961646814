$spinDuration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  font-weight: normal;
}

.button {
  min-height: 40px;
  box-sizing: border-box;
  display: inline-block;
  background-color: $white;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $borderGrey;
  border-radius: 24px;
  color: $black;
  font-size: $smallSize;
  font-weight: 700;
  font-family: inherit;
  padding: $boxSizeMargin * 0.95 $boxSizeMargin * 2.4;
  position: relative;
  transition: background-color ease-in-out 0.2s;
  box-sizing: border-box;

  &:hover,
  &:focus {
    background: $black;
    color: $white;
  }
}

.button--disabled {
  &:hover,
  &:focus {
    background: $white;
  }
}

.button--small {
  padding: 0.7rem;
}
.button--big {
  font-size: $mediumSize;
}

.button--primary {
  color: $white;
  background-color: $blue;
  border: 0px;

  &:hover,
  &:focus {
    background: $blueEnforced;
  }
}

.button--thirdly {
  color: $black;
  background-color: $pink;
  border: 0px;

  &:hover,
  &:focus {
    color: $black;
    background: $pinkEnforced;
  }
}

.button--ghost {
  color: $white;
  background-color: transparent;
  border-color: $white;

  &:hover,
  &:focus {
    color: $black;
    background: $white;
  }
}

.button--link,
.button--link-reverse {
  background: transparent;
  border: none;
  font-size: $regularSize;
  text-decoration: none;
  padding: 0.5rem;
  margin: -0.5rem;

  &:hover,
  &:focus {
    color: $brandPrimary;
    background: transparent;
  }
}
.button--link {
  color: $black;
}
.button--link-reverse {
  color: $white;
}

.button--warning {
  border: 1px solid $warning;
  color: $warning;
}

.button--icon {
  margin: -0.5rem;
  padding: 0.5rem;
  border: none;
  color: inherit;
  background: transparent;

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    color: $brandPrimary;
    background: transparent;
  }
  &:focus {
    outline: 0;
  }
}

.button--disabled {
  cursor: not-allowed;
  border-color: $borderGrey;
  background: $white;
  color: $blackDisabled;
  &.button--primary {
    background: $blueDisabled;
    color: $white;
  }
  &.button--thirdly {
    background: $pinkDisabled;
    color: $blackDisabled;
  }
  &.button--ghost {
    background: transparent;
    color: $white;
  }
}

.button--round {
  border-radius: 50%;
  color: $white;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  height: $boxSizeMargin * 4;
  width: $boxSizeMargin * 4;
  display: flex;
  align-items: center;
  justify-content: center;

  @extend .button--primary;
}

.button--pending {
  color: $white;
  cursor: pointer;
  border: 1px solid $borderGrey;

  &.button--primary,
  &.button--thirdly,
  &.button--ghost {
    color: transparent;
  }
  &.button--warning {
    color: transparent;
    border: 1px solid $warning;
  }
  &.button--link {
    border: none;
  }
  &.button--icon {
    color: transparent;
    border: none;
    background: transparent;

    > * {
      color: transparent;
    }
  }

  &:hover,
  &:focus {
    color: transparent;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    border: 1px solid $shade04;
    border-top-color: $shade05;
    animation: spin $spinDuration infinite linear;
  }
}
