.reassurance {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: $grey;
  @include container-maxwidth();
  padding-top: $boxSizeMargin * 2.4;
  padding-bottom: $boxSizeMargin * 2.4;
  margin-bottom: $boxSizeMargin * 0.8;

  &__item {
    padding-bottom: $boxSizeMargin * 1.7;
    text-transform: uppercase;

    .labelled-icon {
      font-size: $extraSmallSize;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .labelled-icon__content > :first-child {
      margin-right: 2rem;
    }
  }

  &--big {
    margin: 0;
    margin-top: 5px;
    align-items: center;
    justify-content: space-around;
    padding-top: $boxSizeMargin * 4;
    padding-bottom: $boxSizeMargin * 4;

    .labelled-icon--vertical .labelled-icon__content > * + * {
      margin-top: $boxSizeMargin * 1.3;
    }

    .reassurance__item {
      padding-bottom: 0;

      + .reassurance__item {
        margin-top: 5 * $boxSizeMargin;
      }

      .labelled-icon__content > :first-child {
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .reassurance {
    @include container-maxwidth();
    margin: $boxSizeMargin * 2.4 auto;

    flex-direction: row;
    justify-content: space-around;
    padding-top: $boxSizeMargin * 5.6;
    padding-bottom: $boxSizeMargin * 5.6;

    .labelled-icon--vertical .labelled-icon__content > * + * {
      margin-top: $boxSizeMargin * 1.3;
    }

    &__item {
      padding-bottom: 0;

      .labelled-icon__content > :first-child {
        margin-right: 0;
      }
    }

    .reassurance__item {
      padding-bottom: 0;

      + .reassurance__item {
        margin-top: 0;
      }
    }
  }
}
