.breadcrumb {
  display: none;
  align-items: center;
  list-style: none;
  font-size: $extraSmallSize;
  padding: 0;
  background-color: $white;
  &__item {
    display: flex;
    align-items: center;
  }

  &__divider {
    margin: 0 $boxSizeMargin * 0.5;
  }
}

@include media-breakpoint-up(lg) {
  .breadcrumb {
    display: flex;
  }
}
