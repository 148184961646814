.menu {
  position: relative;
  z-index: 3;
  &--active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.empty-header-wrapper {
  height: 48px;
  width: 100%;
}
.header {
  background: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  border-bottom: 1px solid $borderGrey;
  .phone {
    display: flex;
    align-items: center;
    .body {
      font-weight: $semiBold;
    }
    .icon {
      display: flex;
    }
    .icon--mini svg {
      height: 13px;
      width: 13px;
    }
  }
  &__search-overlay {
    position: fixed;
    background: black;
    opacity: 0.7;
    width: 100%;
    height: 100vh;
    left: 0;
    display: none;
    z-index: 2;
    margin-top:3em;

    .search--opened & {
      display: block;
    }
  }
}

@include media-breakpoint-up(menuBreakpoint) {
  .header {
    position: initial;
    border-bottom: none;
  }
}
