.ReactModal__Content .account-form {
  .account-form--register__image,
  .account-form__image {
    display: none;
  }
}

.account-form--register {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__wrapper {
    @include mobile-maxwidth;

    z-index: 1;
    padding: $boxSizeMargin * 2;
  }

  > .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  &__connection__title,
  .login-register__title {
    height: $boxSizeMargin * 6.5;
    margin-bottom: $boxSizeMargin * 2;
    background: $pink;
    .h5 {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      line-height: $boxSizeMargin * 6.5;
      text-align: center;
    }
  }

  &__connection {
    background-color: $white;
    border: 1px solid $borderGrey;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .alert--error a {
      font-weight: 800;
      text-decoration: underline;
    }

    &__form {
      padding: 0 $boxSizeMargin * 2 $boxSizeMargin * 3 $boxSizeMargin * 2;

      > :first-child {
        margin-bottom: $boxSizeMargin;
      }
      .button {
        width: auto;
      }
      .link {
        color: $brandPrimary;
        text-align: right;
        width: 100%;
        display: inline-block;
        margin: $boxSizeMargin * 2 0 $boxSizeMargin * 3 0;
      }
    }
  }

  &__sign-up > :first-child,
  &__connection > :first-child {
    text-align: center;
  }

  &__register-link {
    display: flex;
  }
}

.login-register {
  background-color: $white;
  border: 1px solid $borderGrey;
  margin-top: $boxSizeMargin;

  &__description {
    padding: 0 $boxSizeMargin * 2 $boxSizeMargin * 3 $boxSizeMargin * 2;

    &__text {
      margin-bottom: $boxSizeMargin * 2;
    }

    &__title {
      margin: 0;
      margin-bottom: $boxSizeMargin * 0.5;
      small {
        font-weight: $bold;
      }
    }

    .button {
      margin: 0 auto;
    }
  }
}
.form-actions .form-actions__element {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include media-breakpoint-up(lg) {
  .account-form--register {
    padding: 0;

    &__wrapper {
      max-width: 117.6rem;
      padding: $boxSizeMargin * 8 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &__sign-up,
    &__connection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      margin: 0 auto;

      &__form {
        width: 17rem;
      }
    }

    &__connection {
      flex: 1;
      padding: $boxSizeMargin * 3.2 $boxSizeMargin * 8.5 $boxSizeMargin * 4.8
        $boxSizeMargin * 3.8;
      justify-content: flex-start;

      &__title {
        width: 100%;
      }

      &__form {
        width: 100%;
      }

      &:last-child {
        border-right: 0;
        padding-right: $boxSizeMargin;
      }
    }

    &__register-link {
      margin-top: $boxSizeMargin * 3;
    }

    &__connection__title,
    .login-register__title {
      margin-bottom: $boxSizeMargin * 3.2;
    }

    .login-register {
      flex: 1;
      margin-top: 0;
      padding: $boxSizeMargin * 3.2 $boxSizeMargin * 6.2;
    }
  }
}
