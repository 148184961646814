.instagram {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: $boxSizeMargin auto;
  max-width: $containerMaxWidth;
  box-sizing: border-box;
  &__posts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    flex-wrap: wrap;
    .button {
      width: 50%;
      padding: 0;
      border: none;
      margin: 0;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }
  // @media screen and (min-width: $containerMaxWidth) {

  // }
  @media screen and (min-width: $menuBreakpoint) {
    .instagram {
      &__posts {
        flex-wrap: nowrap;
        .button {
          width: 375px;
          flex: 1;
        }
      }
    }
  }
}
