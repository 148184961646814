.contact-card {
  width: 100%;
  max-width: 220px;
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  padding: $boxSizeMargin $boxSizeMargin $boxSizeMargin * 2 $boxSizeMargin;

  font-size: $smallSize;
  &__title {
    margin-bottom: $boxSizeMargin * 2;

    .h5 {
      margin-bottom: $boxSizeMargin * 0.5;
      margin-top: 0;
    }
  }

  &__infos {
    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-up(sm) {
    width: 220px;
  }
}
