.swipe {
  position: relative;
  overflow: hidden;
  display: inline-block;
  overflow: hidden;

  &__previous,
  &__current,
  &__next {
    will-change: transform;
    width: 100%;
    text-align: center;
  }
  &__previous {
    position: absolute;
  }
  &__next {
    top: 0;
    position: absolute;
  }
}
