.layer-related__filters {
  display: none;

  &--open {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: $white;
    touch-action: none;
  }
}
.layer-related__navigation {
  width: 100%;
  box-sizing: border-box;
  max-width: $maxWidthMobile;
  margin: 0 auto;

  &__title {
    text-align: center;
    position: relative;
    padding: $boxSizeMargin * 2 0;
    border-bottom: 1px solid $borderGrey;

    .button--icon {
      position: absolute;
      right: $boxSizeMargin * 1.6;
      top: 50%;
      transform: translateY(-30%);
    }
  }

  &__actions {
    margin-top: 3rem;
    text-align: center;
  }
}

.layered-navigation {
  padding: 0 $boxSizeMargin * 1.6;
  max-height: 90vh;
  overflow: auto;
}

@include media-breakpoint-up(lg) {
  .layer-related__navigation {
    min-width: 30rem;
    max-width: 35rem;
    background: $white;

    .layered-navigation {
      max-height: none;
      padding: 0 $boxSizeMargin * 1.6 0 0;
    }
  }
}
