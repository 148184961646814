.hero {
  width: 100%;
  background-color: $white;
  @include container-maxwidth();
  padding: 0;
  &__link {
    display: block;
    padding: 0;
    margin: 0;
  }
  &__image {
    .parallax-outer {
      z-index: 1;
    }
    &__loader {
      width: 100%;
      height: 100%;
      background-color: $white;
      position: relative;
      overflow: hidden;
      &::after {
        @keyframes progress {
          0% {
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            transform: translate3d(100%, 0, 0);
          }
        }
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          $grey,
          lighten($borderGreyLight, 7%),
          $grey
        );
        animation: progress 2s ease-in-out infinite;
      }
    }
  }
  .image {
    height: 259px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    text-align: center;

    .h1 {
      position: relative;
      z-index: 1;
      margin-top: -30px;
      padding: 0 20px;
      margin-bottom: 5px;
      font-family: $secondaryFontFamily;
      font-weight: $extraBold;
      font-size: $giantSize;
      color: $black;
    }
  }
  @include media-breakpoint-up(menuBreakpoint) {
    .image {
      height: 625px;
      width: 100%;
    }
    &__content {
      .h1 {
        margin-top: -55px;
        font-size: 68px;
      }
    }
  }
}
