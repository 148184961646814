.modal {
  width: 100%;
  max-width: 30em;
  outline: none;
  height: 100vh;
  overflow: auto;
  background-color: $white;

  &__title {
    background-color: $pink;
    width: 100%;
    padding: $boxSizeMargin * 2 0;
    text-align: center;
    display: inline-block;
    font-size: $mediumSize;
    font-weight: $bold;
    font-family: $secondaryFontFamily;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 50;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__content {
    position: relative;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    box-sizing: border-box;
    &:before {
      content: "";
      padding: $boxSizeMargin * 2 0;
      width: 100%;
      display: block;
      height: 30px;
    }
    &__main {
      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__close {
    text-align: right;
    color: black;
    margin-bottom: $boxSizeMargin * 0.25;
    position: fixed;
    top: 20px;
    right: 30px;
    z-index: 2;
    * {
      text-shadow: 0 0 0.3em rgba(0, 0, 0, 1);
    }
  }

  &--full,
  &--full-no-spacing {
    max-width: 100ch;
  }
  &--full-no-spacing &__content {
    padding: 0;
  }
}

@media screen and (min-width: #{$menuBreakpoint}) {
  .modal {
    min-width: 38em;
    &--full,
    &--full-no-spacing {
      max-width: 100ch;
    }
    &__title {
      position: initial;
    }
    &__close {
      position: absolute;
    }
    &__content {
      &:before {
        display: none;
      }
    }
  }
}
