.product-added {
  box-sizing: border-box;
  padding: 4rem;
  margin: auto;
  &:before {
    display: none;
  }
  &_background {
    .button--icon-icon {
      display: inline-block;
      width: 40px;
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
    }
  }
  .related-products {
    margin-top: $boxSizeMargin;
    .h3 {
      text-align: center;
      display: block;
      font-size: $regularSize;
      font-weight: $semiBold;
      font-family: $secondaryFontFamily;
    }
  }
  &__title {
    text-align: center;
    padding: $boxSizeMargin;
    background: $brandSecondary;

    .h4 {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: $boxSizeMargin;
    }

    small {
      color: $darkGrey;
    }
  }

  .form-actions {
    flex-direction: column;
    padding: 0 $boxSizeMargin * 2;

    > * {
      width: 100%;
      margin: 0;
      text-align: center;
      margin: $boxSizeMargin * 0.5 0;
      max-width: 23rem;

      .labelled-icon {
        &__content {
          > :first-child {
            margin-right: $boxSizeMargin * 1.5;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .product-added {
    width: 90vw;
    max-width: 120rem;
    .related-products {
      margin-top: $boxSizeMargin * 3;
    }
    &__wrapper {
      background: $brandSecondary;
    }

    &__title {
      padding-top: $boxSizeMargin;
    }

    .form-actions {
      width: auto;
      flex-direction: row;
      padding-bottom: $boxSizeMargin * 2;

      > * {
        margin: $boxSizeMargin * 0.5 $boxSizeMargin * 0.8;
      }
    }
  }
}
