.price {
  font-weight: $bold;
  white-space: nowrap;

  &--free {
    color: $success;
    text-transform: uppercase;
  }
}

@import "~theme/components/atoms/Typography/Price/ProductPrice";
